import React from 'react'
import { Badge, Rate, Typography } from 'antd'
import CheckMark from '../assets/images/check-icon.svg'
import { SecondaryButton } from './button'
import { capitalizeFirstWordOfEveryLetter, nameFormat, removeSubdomainFromUrl } from '../../../../../../utils/helper'
import ROUTES from '../../../../../../utils/routes'

const { Title, Paragraph } = Typography


const ItcProfessionals = ({ itcContent, brandColor }) => {

    if(!itcContent?.Members?.length) return null

    return (
    <section className='itc-professionals-section' id="team">
        <div className="itc-professionals-ctn">
            <div className="professionals-title">
                <div className="itc-top-border" style={{background:brandColor}}></div>
                <Title level={1} className="itc-professionals-heading">Our professionals</Title>
            </div>
            <div className="professionals-content">
                {/* <div className="professionals-categories">
                    {
                        categories.map((category) => {
                            return (
                                <div 
                                    className="professionals-category-item" 
                                    key={category.id}
                                    style={{
                                        borderColor:category.title === "All" ? brandColor : "#D7D7D7",
                                        color:category.title === "All" ? brandColor : "#000",
                                    }}
                                >
                                  {category.title}
                                </div>
                            )
                        })
                    }
                </div> */}
                <div className="professionals-card-ctn">
                    {
                        itcContent?.Members?.length &&
                        [
                            ...itcContent?.Members.filter(member => itcContent?.Owner_Contact === member?.Member_Contact?.id), 
                            ...itcContent?.Members.filter(member => itcContent?.Owner_Contact !== member?.Member_Contact?.id)
                        ].map((member) => {
                            return (
                                <div className="professional-card" key={member.id}>
                                   <div className="inner-card">
                                        <div className="top-info">
                                            <div className="verified-label" style={{backgroundColor:brandColor}}>
                                                <img src={CheckMark} alt="check mark" />
                                                <Paragraph className="verified-text">verified</Paragraph>
                                            </div>
                                           { 
                                                member?.Average_Rating &&
                                                <div className="rating-label">
                                                    <Paragraph className="rating-text">{Math.round(member?.Member_Contact?.Average_Rating)}</Paragraph>
                                                    <Rate disabled defaultValue={Math.round(member?.Member_Contact?.Average_Rating)} count={1} style={{color:brandColor, fontSize:14, marginTop:4}} />
                                                    <Paragraph className="reviews-text">({member?.Member_Contact?.Total_Rating})</Paragraph>
                                                </div>
                                            }
                                        </div>
                                        <div className="profile-img-ctn">
                                            <img src={member?.Member_Contact?.imgUrl} alt="profile image" />
                                        </div>
                                   </div>
                                   <div className="bottom-info">
                                        <div className="name-rate">
                                            <Title level={4} className="professional-name">{capitalizeFirstWordOfEveryLetter(nameFormat(`${member?.Member_Contact?.First_Name} ${member?.Member_Contact?.Name}`))}</Title>
                                            <Badge status={itcContent?.Owner_Contact === member?.Member_Contact?.id ? "success" : "default"} text={itcContent?.Owner_Contact === member?.Member_Contact?.id ? "Owner" : `Member`} />
                                            {/* <Paragraph className="professional-rate">${member?.hourlyRate}/hr</Paragraph> */}
                                        </div>
                                        <SecondaryButton 
                                            buttonStyle={{
                                                fontSize: '16px',
                                                color:brandColor,
                                                width:"100%"
                                            }}
                                            onClick={() => {
                                                window.open(`${removeSubdomainFromUrl(itcContent?.Subdomain)}${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${member?.Member_Contact?.id}`)}`, '_blank')	
                                                window.open(
                                            )}}
                                        >
                                            View Profile
                                        </SecondaryButton>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default ItcProfessionals