import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Input, List, Rate, Row, Skeleton, Space, Typography, notification } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'
import { getAlgoliaIndex } from '../../../../common/public/instant-search/utils/helper'
import axios from 'axios'
import { useParams, useHistory } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'
import TechCard from './tech-card'

const { Title, Paragraph } = Typography

const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const TechTeam = ({handleNextClick, itcId, techTeam, setTechTeam, fetchTechTeam, isFromItcProfile =  false}) => {
    const tech = useSelector(state => state.tech)
    const [searchQuery, setSearchQuery] = useState('')
    const [techProfiles, setTechProfiles] = useState([])
    const [loadingTechs, setLoadingTechs] = useState(true)
    const [totalHits, setTotalHits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [invitingMember, setInvitingMember] = useState(true)
    const [selectedMemberId, setSelectedMemberId] = useState('')
    const param = useParams()
    const history = useHistory()

    const addMemberToTeam = (member) => {
        setInvitingMember(true)
        ApiBaseHelper.post({
            url: `itc/member`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload:{
                Member_Contact: member?.objectID,
                Member_Org:member?.org_id,
                Status: "Invited",
                // Work_Category: member?.work_categories.join("|"),
                ITC: isFromItcProfile ? param.id : itcId,
            }
            
        }).then((res) => {
            setInvitingMember(false)
            notification.success({
                message: 'TecMe',
                description: 'Invitation email sent successfully'
            })
            fetchTechTeam()
        }).catch((err) => {
            setInvitingMember(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while inviting technician'
            })
            console.log(err)
        })
    }

    const addOwnerToTeam = () => {
        setLoadingTechs(true)
        ApiBaseHelper.post({
            url: `itc/member`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload:{
                Member_Contact: tech?.id,
                Member_Org:tech?.orgId,
                Status: "Active",
                // Work_Category: member?.work_categories.join("|"),
                ITC: itcId,
            }
            
        }).then((res) => {
            setLoadingTechs(false)
        }).catch((err) => {
            setLoadingTechs(false)
            console.log(err)
        })
    }

    // fetch tech profiles
    const fetchTechProfiles = async () => {
        // setLoadingTechs(true)
        try {
            const response = await axios.get(
              `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
              {
                headers: {
                  'X-Algolia-API-Key': algoliaSearchKey,
                  'X-Algolia-Application-Id': algoliaAppID,
                },
                params: {
                    hitsPerPage: pageSize,
                    page: currentPage - 1,
                    query: searchQuery,
                },
              }
            );
      
            // Extract and store the search results from the response
            setTechProfiles(response.data.hits.filter((item) => item.objectID !== tech.id));
            // setTechProfiles(response.data.hits);
            setTotalHits(response.data?.nbHits);
            setLoadingTechs(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingTechs(false);
        }
    }

    const handleTechSearch = (e) => {
        setSearchQuery(e.target.value)
        setCurrentPage(1)
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        window.scrollTo(0, 0)
    };

    useEffect(() => {  
        fetchTechProfiles()
    }, [pageSize, currentPage, searchQuery])

    useEffect(() => {
        fetchTechTeam()
    }, [techProfiles])

    useEffect(() => {
        !isFromItcProfile && addOwnerToTeam()
    }, [])

    return (
        <div className="itc-team-section">
            <div className="itc-team-ctn">
                <div className="itc-team-form-ctn">
                    <Title level={4} style={{fontSize:16}} className="itc-form-title">Filter technicians by name or location</Title>
                    <Input 
                        size='large'
                        placeholder="Ex. John Doe"
                        onChange={handleTechSearch}
                    />
                    <div className="itc-tech-team">
                    {
                        loadingTechs ? <LoadingTechCards/> : 
                        <CustomRenderEmpty
                            description={"No available technicians"} 
                        > 
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 3
                                }}
                                // className="tech-team-list-ctn"
                                dataSource={techProfiles}
                                pagination={{ 
                                    pageSize: pageSize,
                                    total: totalHits,
                                    onChange: handlePageChange,
                                    current: currentPage,
                                 }}
                                renderItem={item => (
                                    <List.Item>
                                       <TechCard 
                                            key={item?.objectID}
                                            techDetails={item}
                                            selectedMemberId={selectedMemberId}
                                            setSelectedMemberId={setSelectedMemberId}
                                            addMemberToTeam={addMemberToTeam}
                                            invitingMember={invitingMember}
                                            techTeam={techTeam}
                                       />
                                    </List.Item>
                                )}
                            />
                        </CustomRenderEmpty>
                    }
                    </div>
                    {
                        !isFromItcProfile &&
                        <Space>
                            <Button
                                className='itc-next-btn' 
                                type='primary' 
                                onClick={() => {
                                    handleNextClick()
                                }}
                            >   
                                Next
                            </Button>
                            <Button 
                                className='itc-next-btn' 
                                type='secondary'
                                onClick={() => {
                                    handleNextClick()
                                }}
                            >
                                Skip
                            </Button>
                        </Space>
                    }
                </div>
            </div>
        </div>
    )
}

export const LoadingTechCards = () => {
    return(
        <Row gutter={[20, 20]}>
        { [...Array(10).keys()].map(() => {
            return(
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card
                        style={{
                            height: "auto",
                            width:"auto",
                        }}
                    >
                        <Skeleton />
                        <Skeleton />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}

export default TechTeam