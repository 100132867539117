// External Imports
import { useState, useEffect } from 'react'
import { Button, Card, Checkbox, Col, Space, Modal, Row, Skeleton, Switch, Typography, Layout, Tabs, Empty, notification, Tooltip, Tag, Popover, Select } from 'antd'
import { BulbTwoTone, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined, ProfileOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { MetaTags } from 'react-meta-tags'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

// Internal Imports
import { META_DESCRIPTION } from '../../../../../../utils/constants'
import ROUTES from '../../../../../../utils/routes'
import PageHeaderWrapper from '../../../../../components/page-header-breadcrumbs'

// Assets Imports
import '../assets/css/create-specialization.scss'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'

const { Content } = Layout
const { TabPane } = Tabs
const { Title, Paragraph, Text } = Typography;
const { Option, OptGroup } = Select;

const CreateSpecialization = () => {
    const tech = useSelector((state) => state.tech);
    const { token } = tech
    const history = useHistory()

    const[categorySkillsData, setCategoriesSkillsData] = useState([])
    const [formattedCategorySkillsData, setFormattedCategorySkillsData] = useState([])
    const [loadingSkills, setLoadingSkills] = useState(false)
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    let allAvailableSkills =[]

    
    
    function handleChange(value) {
       setSelectedCategoryId(value)
    }
  
    function handleSearch(value) {
        console.log(`Searched ${value}`);
    }


    const fetchCategorySkills = () => {
        setLoadingSkills(true)
        ApiBaseHelper.get({
            url: `work-categories`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setCategoriesSkillsData(res.data)
            setLoadingSkills(false)
        }).catch((error) => {
            console.error(error)
            setLoadingSkills(false)
        })       
    }


    useEffect(() => {
        fetchCategorySkills()
    }, [])

    useEffect(() => { 
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        if(categorySkillsData.length > 0) {
            let formattedData = []
            categorySkillsData.map((category) => {
                let all_skills = []
                all_skills.push(
                    {
                        id: category.id,
                        parent_id: category.id,
                        Title: category.Title,
                        Description: category.Description,
                        other_skills: category.Skills
                    }
                )
                category.Child_Categories.map((item) => {
                    all_skills.push({
                        id: item.id,
                        parent_id: item.Parent_Category,
                        Title: item.Title,
                        Description: item.Description,
                        other_skills: category.Skills
                    })
                })
                formattedData.push({
                    all_skills: all_skills, 
                })
            })
            setFormattedCategorySkillsData(formattedData)
        }

    }, [categorySkillsData])

    allAvailableSkills = formattedCategorySkillsData?.length && [].concat(...formattedCategorySkillsData.map(obj => obj.all_skills));
    
 
    return (
    <>
        <MetaTags>
            <title>Create Specialization | TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow"/>
        </MetaTags>
        <Content className="main">
            <div className="page">
                <div style={{minHeight: '800px'}}>
                    <PageHeaderWrapper
                        className="tech-skill-page-breadcrumb-header"
                        routes={[
                            {
                                path:  ROUTES.MANAGE_SKILLS,
                                breadcrumbName: 'Work Preferences',
                            },
                            {
                                breadcrumbName: 'Create Specialization',
                            },
                        ]}
                    />
                    <div className='auth-container'>
                        <div className='create-specialization-ctn skills-ctn'>
                            <div className="create-specialization-top">
                                <Title level={4}>Create Specialization</Title>
                                <Paragraph style={{fontSize:16, margin:0}}>By creating specialized profiles , you can showcase your skills and experience in different areas, increasing your visibility to potential clients and finding work that aligns with your unique abilities. This can help you stand out from the competition and increase your earning potential in a competitive job market.</Paragraph>
                            </div>
                            <div className="create-specialization-body">
                                <Title style={{fontSize:18}} level={5}>Select a specialization to begin</Title>
                                <Select
                                    popupClassName='create-specialization-select'
                                    showSearch
                                    listHeight={264}
                                    placeholder="Please select"
                                    optionFilterProp="children"
                                    onChange={handleChange}
                                    onSearch={handleSearch}
                                    showArrow={false}
                                    virtual={false}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                        {
                                            allAvailableSkills?.length && allAvailableSkills?.map((skill, index) => {
                                                return (
                                                    <Option className={skill.id === skill.parent_id && 'title-option'} value={skill.id}>{skill.Title}</Option>
                                                )
                                            })
                                        }
                                </Select>
                                <Space style={{marginTop:60}} align="start">
                                    <BulbTwoTone style={{fontSize:20, color:"#F04A22"}} twoToneColor="#F04A22"/>
                                    <Paragraph style={{margin:0, color:"#6B6B6D"}}><Text style={{fontWeight:500, color:"#6B6B6D"}}>Tip:</Text>  It's best to specialize in the categories you feel most confident providing support and services in. You can find <Text style={{color:"#F04A22", fontWeight:500}}>a complete list of all the available areas of expertise and their descriptions</Text> to help you make the right choice.</Paragraph>
                                </Space>
                            </div>
                            <Space size={24} style={{paddingRight:24}} className='mt-3 mb-5 w-100 justify-content-end'>
                                <Button type='secondary' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() => history.goBack()}>
                                    Cancel
                                </Button>
                                <Button 
                                    type="primary" htmlType="submit" 
                                    style={{height:40, fontSize:16}} 
                                    onClick={() => history.push({
                                    pathname: ROUTES.UPDATE_SPECIALIZATION.replace(':specialization_id', selectedCategoryId),
                                    state: {categorySkillsData: allAvailableSkills},
                                    search:'?option=create'
                                    })}
                                    disabled={selectedCategoryId === null}>
                                    Continue
                                </Button>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
        </Content>  
    </>
  )
}

export default CreateSpecialization