import React, {Component, Fragment} from 'react';
import {
    Alert,
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Image,
    Input,
    Layout,
    Modal,
    notification,
    Result,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    Typography
} from 'antd'
import {connect} from "react-redux";
import ROUTES from '../../../../../utils/routes';
import {BookingRepository} from './repository/repository';
import {DATE_TIME_FORMAT, META_DESCRIPTION, SECONDARY_COLOR} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import {Link} from "react-router-dom";
import Moment from 'moment';
import {extendMoment} from "moment-range";
import DesktopOutlined from "@ant-design/icons/lib/icons/DesktopOutlined";
import CarryOutOutlined from "@ant-design/icons/lib/icons/CarryOutOutlined";
import AimOutlined from "@ant-design/icons/lib/icons/AimOutlined";
import VideoCameraOutlined from "@ant-design/icons/lib/icons/VideoCameraOutlined";
import {isDesktop} from "react-device-detect";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import PaymentMethods from "../../../customer/book-service/components/payment-method-list";
import DollarOutlined from "@ant-design/icons/lib/icons/DollarOutlined";
import {canReadPageOnly, capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, ucwords} from "../../../../../utils/helper";
import RightCircleOutlined from "@ant-design/icons/lib/icons/RightCircleOutlined";
import AddIcon from '@mui/icons-material/Add';
import NoResultsImage from "./assets/images/no-results.png";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import QuestionOutlined from "@ant-design/icons/lib/icons/QuestionOutlined";
import TechConnectModal from "../../../../components/modals/tech-connect-modal";
import CustomRenderEmpty from '../../../../components/custom-render-empty';
import PageTitle from '../../../../components/page-title';

const {RangePicker} = DatePicker;
const moment = extendMoment(Moment);

const {Content,} = Layout
const {Text, Title, Paragraph} = Typography;

class HomePage extends Component {
    filterFormRef = React.createRef();
    serviceTableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            servicesLoading: true,
            subscriptionsLoading: true,
            paymentMethodModalVisible: false,
            showFilters: isDesktop,
            services: [],
            customerSelectedPaymentMethod: null,
            filteredData: [
                /*
                META DATA
                 */
                // {
                //     serviceId: '4232323',
                //     title: 'Custom support ticket',
                //     user: {
                //         name: 'Osama B.',
                //         type: 'tech',
                //         id: '123123',
                //         profilePic: 'https://static-01.daraz.pk/p/aec7101b86e4d8761ce7f70d341747eb.jpg',
                //     },
                //     supportType: 'ON SITE',
                //     status: 'Pending',
                //     scheduledDateTime: '2022-05-27T23:11:22.000Z',
                //     bookingRealStatus: 'Pending',
                //     bookingDisplayStatus: 'Active',
                //     bookingId: '123123'
                // }
            ],
            subscriptions: [],
        };
        this.getServices = this.getServices.bind(this);
        this.getSubscriptions = this.getSubscriptions.bind(this);
        this.cancelSubscriptionModal = this.cancelSubscriptionModal.bind(this);

        this.selectedSubscriptionId = null;
        this.user = this.props.user ? this.props.user : this.props.tech;
        this.bookingRepository = new BookingRepository(this.props.user ? this.props.user.token : this.props.tech.token, this.props.user ? 'customer' : 'tech')
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.getServices();
        this.getSubscriptions();

    }

    async cancelSubscriptionModal(record) {
        let _this = this;
        Modal.confirm({
            title:  <Title level={3} className='font-weight-500'>Are you sure?</Title>,
            icon: null,
            cancelText: "Cancel",
            okText: "Confirm",
            cancelButtonProps: {type: "default"},
            okButtonProps: { danger: true, type: 'primary'},
            content: <Paragraph className='my-2 disclaimer p-2'>
            Cancels a subscription immediately. The customer will not be charged again for the subscription.
            </Paragraph>,
            async onOk() {
                _this.setState({
                    subscriptionsLoading: true
                });
                await _this.bookingRepository.cancelSubscription(record.id);
                notification.open({
                    message: 'TecMe',
                    description: 'Subscription cancelled',
                });
                _this.getSubscriptions();
            },
        });
    }

    async getSubscriptions() {
        setTimeout(async function () {
            let subscriptionsResponse = await this.bookingRepository.getSubscriptions();
            let subscriptions = subscriptionsResponse.data.data;
            this.setState({
                subscriptions: subscriptions.filter((record) => (record.status != 'canceled')),
                subscriptionsLoading: false
            })
        }.bind(this), 5000)

    }

    async getServices() {
        //get bookings
        let bookingsResponse = await this.bookingRepository.getBookings();
        let bookings = bookingsResponse.data.data;
        if (this.props.tech) {
            // if tech logged in, get open and direct requests
            let requestsResponse = await this.bookingRepository.getRequests();
            let serviceRequests = requestsResponse.data.data;
            bookings = bookings.concat(serviceRequests.openRequests);
            bookings = bookings.concat(serviceRequests.yourRequests);
            // bookings = bookings.reverse();
        }
        let services = [];
        let paymentRequiredServices = [];
        let requestedServices = [];
        bookings.map((booking, bookingIndex) => {
            // determine if all services are completed and tech has to send the invoice to customer
            let completedServicesCount = 0;
            let feeItems = 0;
            booking.items.map((service) => {
                if (service['status'] == 'Completed' || service['status'] == 'Cancelled') {
                    completedServicesCount++;
                }
                if (service['type'] == 'fee')
                    feeItems = 1;
            });

            let bookingStatus = 'Active';
            if (booking.status == 'Confirmed')
                bookingStatus = 'Paid';
            else if (booking.status == 'Completed')
                bookingStatus = 'Payment Required';
            else if (booking.status == 'Cancelled')
                bookingStatus = booking.status;
            else if (booking.accepted == false && this.props.tech)
                bookingStatus = 'New Request';
            else if (booking.accepted == false && this.props.user)
                bookingStatus = 'Pending';
            else if (booking.status == 'Pending' && (completedServicesCount == booking.items.length - feeItems))
                bookingStatus = 'Ready To Invoice'

            booking.items.map((service, serviceIndex) => {
                if (service.type == 'service') {
                    let serviceInsert = {
                        serviceId: service.scheduledServiceId,
                        title: service.issue ? service.issue.title : 'N/A',
                        user: {
                            name: service.user.name,
                            type: service.user.type,
                            id: service.user.type == 'customer' ? service.customerContact : service.techContact,
                            profilePic: service.user.profilePic,
                        },
                        userName: service.user.name != null ? service.user.name : 'N/A',
                        supportType: ucwords(service.mode.replace("_", " ")),
                        status: service.status == 'Accepted' ? 'Scheduled' : service.status,
                        scheduledDateTime: service.scheduledDateTime,
                        isRequest: bookingStatus == 'New Request',
                        bookingRealStatus: booking.status,
                        bookingDisplayStatus: bookingStatus,
                        bookingId: booking.bookingId
                    };
                    if (bookingStatus == 'Payment Required' && this.props.user) {
                        paymentRequiredServices.push(serviceInsert)
                    }
                    else if (bookingStatus == 'New Request' && this.props.tech) {
                        requestedServices.push(serviceInsert)
                    }
                    else
                        services.push(serviceInsert);
                }
            });
        });
        services = services.sort((a, b) => b.scheduledDateTime.localeCompare(a.scheduledDateTime));
        paymentRequiredServices = paymentRequiredServices.sort((a, b) => b.scheduledDateTime.localeCompare(a.scheduledDateTime));
        requestedServices = requestedServices.sort((a, b) => b.scheduledDateTime.localeCompare(a.scheduledDateTime));
        services = paymentRequiredServices.concat(services);
        services = requestedServices.concat(services);
        this.setState({
            services,
            filteredData: [...services].sort((a, b) => moment(b.scheduledDateTime).local().diff(moment(a.scheduledDateTime).local())),
            servicesLoading: false
        })
    }

    render() {
        const {servicesLoading, services, filteredData, subscriptions, subscriptionsLoading, paymentMethodModalVisible, showFilters, customerSelectedPaymentMethod} = this.state;
        let _this = this;
        return (
            <Fragment>
                <MetaTags>
                    <title>Home Dashboard - TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <Content className="main">
                    <TechConnectModal />
                    <Modal visible={paymentMethodModalVisible}
                           okText={"Change Payment Method"}
                           okButtonProps={{type:"primary", disabled: customerSelectedPaymentMethod == null}}
                           cancelButtonProps={{style: {display: 'none'}}}
                           onOk={async (e) => {
                               _this.setState({
                                   subscriptionsLoading: true,
                                   paymentMethodModalVisible: false,
                               });
                               await _this.bookingRepository.changeSubscriptionPaymentMethod(this.selectedSubscriptionId, customerSelectedPaymentMethod);
                               notification.open({
                                   message: 'TecMe',
                                   description: 'Payment method changed successfully.',
                               });
                               _this.setState({
                                   subscriptionsLoading: false,
                               });
                           }}
                           onCancel={() => {
                               _this.setState({
                                   paymentMethodModalVisible: false
                               })
                           }}
                    >
                        <div>
                            <Title level={4} style={{padding: 0, margin: 0, marginBottom: '1rem'}}>Select a payment method</Title>
                        </div>
                        <PaymentMethods xl={24} lg={24} md={24}
                                        onChange={(pm) => {
                                            this.setState({
                                                customerSelectedPaymentMethod: pm
                                            })
                                        }}/>
                        <Paragraph style={{marginTop: '1rem'}}>
                            Want to add new payment method? <Link to={ROUTES.ADD_PAYMENT} style={{color: SECONDARY_COLOR}}>Click here</Link>
                        </Paragraph>
                    </Modal>
                    <div className="page">
                        
                            <Row gutter={[20, 20]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <PageHeaderWrapper
                                        routes={[
                                            {
                                                path: ROUTES.MANAGE_BOOKING,
                                                breadcrumbName: 'IT Services',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="support-ticket-section">
                                <div className="auth-container">
                                    <div className='auth-page-ctn'>
                                        <PageTitle 
                                            title="IT Services"
                                            extra={ this.props.tech && [
                                                <Button href={ROUTES.MY_SERVICE}>
                                                   Manage Services
                                                </Button>
                                            ] }
                                        />
                                        <Card
                                        title="Scheduled Services"
                                        extra={
                                            this.props.user && isDesktop ?
                                                <Row gutter={[10,10]}>
                                                    {
                                                        !canReadPageOnly(this.props?.user?.role) &&
                                                        <Col>
                                                            <Button >
                                                                <Link to={ROUTES.QUICK_BOOK}>
                                                                    + Book A New IT Service
                                                                </Link>
                                                            </Button>
                                                        </Col>
                                                    }
                                                    <Col>
                                                        <Button type={"primary"}>
                                                            <Link to={ROUTES.DISCOVERY_EXPERIENCE}>
                                                                Discover Technicians
                                                            </Link>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                    >
                                       
                                        <Row gutter={[15, 15]}>
    
                                            { // mobile only
                                                servicesLoading &&
                                                <>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                services.length > 0 && servicesLoading == false &&
                                                <>
                                                    <Col span={24}>
                                                        {
                                                            showFilters &&
                                                            <Form
                                                                ref={this.filterFormRef}
                                                                // className={"text-center"}
                                                                layout="vertical"
                                                                name="services_filter_form"
                                                                initialValues={{
                                                                    title: '',
                                                                    status: 'all',
                                                                    supportType: 'all',
                                                                    bookingStatus: 'all',
                                                                }}
                                                                onFinish={(values) => {
                                                                    console.log(values);
                                                                    let searchResults = [];
                                                                    let title = values.title;
                                                                    let status = values.status;
                                                                    let bookingStatus = values.bookingStatus;
                                                                    let supportType = values.supportType;
                                                                    let scheduleDate = values.scheduleDate;
                                                                    services.map((service, index) => {
                                                                        if (
                                                                            ((title != '' && service.title.toLowerCase().includes(title.toLowerCase())) || (title == ''))
                                                                            &&
                                                                            ((scheduleDate && (moment(scheduleDate[0]).isBefore(moment(service.scheduledDateTime)) && moment(scheduleDate[1]).isAfter(moment(service.scheduledDateTime)))) || (!scheduleDate))
                                                                            &&
                                                                            ((status.toLowerCase() == service.status.toLowerCase()) || (status == 'all'))
                                                                            &&
                                                                            ((supportType.toLowerCase() == service.supportType.toLowerCase()) || (supportType == 'all'))
                                                                            &&
                                                                            ((bookingStatus.toLowerCase() == service.bookingDisplayStatus.toLowerCase()) || (bookingStatus == 'all'))

                                                                        ) {
                                                                            searchResults.push(service);
                                                                        }
                                                                    });

                                                                    this.setState({
                                                                        filteredData: searchResults
                                                                    });
                                                                }}
                                                            >
                                                                <Row gutter={[10, 10]} align={"bottom"}>
                                                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                                                        <Form.Item
                                                                            label="Short Description"
                                                                            name="title"
                                                                        >
                                                                            <Input
                                                                                placeholder="e.g Laptop support"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                                                        <Form.Item name="scheduleDate" label="Scheduled Date">
                                                                            <RangePicker
                                                                                style={{width: '100%'}}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                                        <Form.Item name="supportType" label="Support Type">
                                                                            <Select
                                                                                defaultValue={"all"}
                                                                            >
                                                                                <Select.Option value="all">All</Select.Option>
                                                                                <Select.Option value="ON SITE">On Site</Select.Option>
                                                                                <Select.Option value="REMOTE ACCESS">Remote Access</Select.Option>
                                                                                <Select.Option value="VIDEO CALL">Video Call</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                                        <Form.Item name="status" label="Status">
                                                                            <Select
                                                                                defaultValue={"all"}
                                                                            >
                                                                                <Select.Option value="all">All</Select.Option>
                                                                                <Select.Option value="Pending">Pending</Select.Option>
                                                                                <Select.Option value="Scheduled">Scheduled</Select.Option>
                                                                                <Select.Option value="In Progress">In Progress</Select.Option>
                                                                                <Select.Option value="Completed">Completed</Select.Option>
                                                                                <Select.Option value="Cancelled">Cancelled</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                                                        <Form.Item name="bookingStatus" label="Booking Status">
                                                                            <Select
                                                                                defaultValue={"all"}
                                                                            >
                                                                                <Select.Option value="all">All</Select.Option>
                                                                                <Select.Option value="Active">Active</Select.Option>
                                                                                <Select.Option value="Paid">Paid</Select.Option>
                                                                                <Select.Option value="Payment Required">Payment Required</Select.Option>
                                                                                {
                                                                                    this.props.tech &&
                                                                                    <Select.Option value="New Request">New Request</Select.Option>
                                                                                }
                                                                                {
                                                                                    this.props.user &&
                                                                                    <Select.Option value="Pending">Pending</Select.Option>
                                                                                }

                                                                                <Select.Option value="Ready To Invoice">Ready To Invoice</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                                                        <Button
                                                                            danger
                                                                            htmlType="submit"
                                                                            style={{marginBottom: 10}}
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        }
                                                        {
                                                            !isDesktop &&
                                                            <Button type="text" danger style={{padding: 0}}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            showFilters: !showFilters
                                                                        });
                                                                    }}
                                                            >
                                                                {this.state.showFilters ? 'Hide Filters' : 'Show Filters'}
                                                            </Button>
                                                        }


                                                    </Col>
                                                    <Col span={24}>
                                                        <Divider className={"mt-2 mb-0"}/>
                                                    </Col>

                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        {
                                                            filteredData.length == 0  &&
                                                           
                                                            <Result
                                                                icon={<Image loading="lazy"  src={NoResultsImage} preview={false} style={{maxWidth: "250px"}}/>}
                                                                title={this.props.user ? "Book A Technician" : "Find New Customer's"}
                                                                subTitle={this.props.user ? "Search for local technicians that can repair your IT devices." : "Begin marketing your profile with the help of TecMe's marketing suite."}
                                                                extra={
                                                                    <Button type={"primary"}
                                                                            onClick={() => {
                                                                                if (this.props.tech)
                                                                                    window.open("http://site.tecme.io/startup-guide", '_blank');
                                                                                else
                                                                                    this.props.history.push('/');
                                                                            }}
                                                                    >
                                                                        {this.props.user ? "Search Now" : "Market Now"}
                                                                    </Button>
                                                                }
                                                            />
                                                        }
                                                        <Row gutter={[10, 10]}>
                                                            {
                                                                filteredData.map((record, index) => {
                                                                    let status = 'warning';
                                                                    if (record.status == 'Pending')
                                                                        status = 'default';
                                                                    else if (record.status == 'Accepted')
                                                                        status = 'warning';
                                                                    else if (record.status == 'In Progress')
                                                                        status = 'processing';
                                                                    else if (record.status == 'Completed')
                                                                        status = 'success';
                                                                    else if (record.status == 'Cancelled')
                                                                        status = 'error';

                                                                    let supportTypeIcon = <AimOutlined style={{fontSize: 20}}/>;
                                                                    if (record.supportType == 'REMOTE ACCESS')
                                                                        supportTypeIcon = <DesktopOutlined style={{fontSize: 20}}/>
                                                                    else if (record.supportType == 'VIDEO CALL')
                                                                        supportTypeIcon = <VideoCameraOutlined style={{fontSize: 20}}/>


                                                                    return <Col key={`service-${index}`} span={24}>
                                                                        <Card
                                                                            title={
                                                                                <Row align={"middle"} gutter={[5.5]}>
                                                                                    <Col>
                                                                                        {
                                                                                            record['user']['name'] == null ?
                                                                                                ''
                                                                                                : record['user']['profilePic'] != null ?
                                                                                                <Avatar src={record['user']['profilePic']}/>
                                                                                                : <Avatar style={{
                                                                                                    color: '#f56a00',
                                                                                                    backgroundColor: '#fde3cf',
                                                                                                    fontSize: 20
                                                                                                }}>{record['user']['name'][0].toUpperCase()}</Avatar>
                                                                                        }
                                                                                    </Col>
                                                                                    {
                                                                                        this.props.user ?
                                                                                            <Col>
                                                                                                {record['user']['name'] != null ?
                                                                                                    <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                            href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['user']['id'])}>{capitalizeFirstWordOfEveryLetter(record['user']['name'])}</Button>
                                                                                                    : 'N/A'}
                                                                                            </Col>
                                                                                            :
                                                                                            <Col>
                                                                                                <Text>{record['user']['name'] != null ? capitalizeFirstWordOfEveryLetter(record['user']['name']) : 'N/A'}</Text>
                                                                                            </Col>
                                                                                    }
                                                                                </Row>
                                                                            }
                                                                        >
                                                                            <Text ellipsis={{rows: 2}} strong>{capitalizeFirstLetter(record.title)}</Text>
                                                                            <Row gutter={[15, 15]} className={"mt-2"}>
                                                                                <Col span={24}>
                                                                                    <Row gutter={[10, 10]} align={"middle"}>
                                                                                        <Col>{supportTypeIcon}</Col>
                                                                                        <Col><Text>{record.supportType}</Text></Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Row gutter={[10, 10]} align={"middle"}>
                                                                                        <Col><CarryOutOutlined style={{fontSize: 20}}/></Col>
                                                                                        <Col> <Text>{moment(record.scheduledDateTime).format('llll')}</Text></Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Badge status={status} text={record.status}/>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Tag>{record.bookingDisplayStatus}</Tag>
                                                                                </Col>
                                                                                <Col xs={24} sm={24} md={12} lg={0} xl={0}>
                                                                                    <Button
                                                                                        danger
                                                                                        style={{width: '100%'}}
                                                                                    >
                                                                                        <Link to={{
                                                                                            pathname: `${ROUTES.SERVICE_DETAILS.replace(':service_id', record.serviceId).replace(":booking_id", record.bookingId)}`,
                                                                                        }}>
                                                                                            View Details
                                                                                        </Link>
                                                                                    </Button>
                                                                                </Col>

                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>

                                                    </Col>
                                                </>
                                            }
                                            {
                                                // (services.length > 0 || servicesLoading == true) &&
                                                <Col xs={0} sm={0} md={0} lg={24} xl={24} className="schedule-services-table">
                                                    <CustomRenderEmpty description={this.props.user ? "Search for local technicians that can repair your IT devices." : "Begin marketing your profile with the help of TecMe's marketing suite."}
                                                    extra={
                                                        <Button
                                                                onClick={() => {
                                                                    if (this.props.tech)
                                                                        window.open("http://site.tecme.io/startup-guide", '_blank');
                                                                    else
                                                                        this.props.history.push('/');
                                                                }}
                                                        >
                                                            {this.props.user ? "Search Now" : "Market Now"}
                                                        </Button>
                                                    }>
                                                        <Table
                                                        style={{marginTop: 20, minHeight: 500}}
                                                        ref={this.serviceTableRef}
                                                        loading={servicesLoading}
                                                        pagination={{
                                                            onChange: (e) => {
                                                                // window.scrollTo(0, 0);
                                                                this.serviceTableRef.current.scrollIntoView()
                                                            },
                                                            showSizeChanger: true
                                                        }}
                                                        onRow={(record) => {
                                                            return {
                                                                onClick: () => this.props.history.push(ROUTES.SERVICE_DETAILS.replace(':service_id', record.serviceId).replace(":booking_id", record.bookingId))
                                                            };
                                                        }}
                                                        rowClassName={(record, index) => {
                                                            let rowClass = '';
                                                            if (record.isRequest)
                                                                rowClass = 'service-request-row';
                                                            else if (record.bookingDisplayStatus == 'Payment Required' && this.props.user)
                                                                rowClass = 'service-payment-required-row';

                                                            return rowClass;
                                                        }}
                                                        columns={[
                                                            {
                                                                title: 'Short Description',
                                                                key: 'title',
                                                                dataIndex: 'title',
                                                                web: true,
                                                                mobile: true,
                                                                width:"12%",	
                                                                className: "service-title-column",
                                                                sorter: {
                                                                    compare: (a, b) => a.title.localeCompare(b.title),
                                                                },
                                                                render: (record) => {
                                                                    return <div style={{width:300}}>
                                                                        <Text ellipsis={{rows: 1}}>{capitalizeFirstLetter(record)}</Text>
                                                                    </div>
                                                                },
                                                            },

                                                            {
                                                                title: this.props.user ? 'Technician' : 'Customer',
                                                                dataIndex: 'user',
                                                                key: 'user',
                                                                // width:"10%",
                                                                web: true,
                                                                mobile: true,
                                                                sorter: {
                                                                    compare: (a, b) => a.userName.localeCompare(b.userName),
                                                                },
                                                                render: (record) => {
                                                                    return <Row align={"middle"} gutter={[5]}>
                                                                        <Col>
                                                                            {
                                                                                record['name'] == null ?
                                                                                    <Avatar style={{
                                                                                        color: '#f56a00',
                                                                                        backgroundColor: '#fde3cf',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                    icon={<QuestionOutlined style={{verticalAlign:0}} />}
                                                                                    />
                                                                                    : record['profilePic'] != null ?
                                                                                    <Avatar src={record['profilePic']}/>
                                                                                    : <Avatar style={{
                                                                                        color: '#f56a00',
                                                                                        backgroundColor: '#fde3cf',
                                                                                        fontSize: 20
                                                                                    }}>{record['name'][0].toUpperCase()}</Avatar>
                                                                            }
                                                                        </Col>
                                                                        {
                                                                            this.props.user ?
                                                                                <Col>
                                                                                    {record['name'] != null ?
                                                                                        <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['id'])}>{capitalizeFirstWordOfEveryLetter(record['name'])}</Button>
                                                                                        : 'Searching for a tech'}
                                                                                </Col>
                                                                                :
                                                                                <Col>
                                                                                    <Text
                                                                                        style={{padding: '4px 5px'}}>{capitalizeFirstWordOfEveryLetter(record['name']) != null ? record['name'] : 'N/A'}</Text>
                                                                                </Col>
                                                                        }
                                                                    </Row>
                                                                },
                                                            },
                                                            {
                                                                title: 'Support Type',
                                                                key: 'supportType',
                                                                dataIndex: 'supportType',
                                                                web: true,
                                                                mobile: false,
                                                                // width:'20%',
                                                                sorter: {
                                                                    compare: (a, b) => a.supportType.localeCompare(b.supportType),
                                                                },
                                                            },
                                                            {
                                                                title: 'Status',
                                                                key: 'status',
                                                                dataIndex: 'status',
                                                                width:'12%',
                                                                web: true,
                                                                mobile: false,
                                                                render: (record) => {
                                                                    let status = 'warning';
                                                                    if (record == 'Pending')
                                                                        status = 'default';
                                                                    else if (record == 'Accepted')
                                                                        status = 'warning';
                                                                    else if (record == 'In Progress')
                                                                        status = 'processing';
                                                                    else if (record == 'Completed')
                                                                        status = 'success';
                                                                    else if (record == 'Cancelled')
                                                                        status = 'error';

                                                                    return <Badge status={status} text={record}/>
                                                                },
                                                                sorter: {
                                                                    compare: (a, b) => a.status.localeCompare(b.status),
                                                                },
                                                            },
                                                            {
                                                                title: 'Scheduled Date & Time',
                                                                dataIndex: 'scheduledDateTime',
                                                                key: 'scheduledDateTime',
                                                                // width:'25%',
                                                                render: (record) => {
                                                                    return <Text>{record == null ? "N/A" : moment(record).format("Do MMM YYYY, h:mm a")}</Text>
                                                                },
                                                                // defaultSortOrder: 'descend',
                                                                web: true,
                                                                mobile: false,
                                                                sorter: {
                                                                    compare: (a, b) => a.scheduledDateTime.localeCompare(b.scheduledDateTime),
                                                                },
                                                            },
                                                            {
                                                                title: 'Booking Status',
                                                                key: 'bookingDisplayStatus',
                                                                dataIndex: 'bookingDisplayStatus',
                                                                web: true,
                                                                mobile: false,
                                                                // width:'30%',
                                                                sorter: {
                                                                    compare: (a, b) => a.bookingDisplayStatus.localeCompare(b.bookingDisplayStatus),
                                                                },
                                                                render: (record) => {
                                                                    if (record == 'Payment Required')
                                                                        return <Badge status={'success'} text={record}/>
                                                                    return record == 'New Request' ?
                                                                        <Badge status={'error'} text={record}/>
                                                                        : <Text>{record}</Text>
                                                                },
                                                            }
                                                        ]}
                                                        dataSource={filteredData}/>
                                                    </CustomRenderEmpty>
                                                </Col>
                                            }

                                        </Row>
                                        </Card>
                                    </div>
                                    
                                    </div>
                                </Col>
                                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="support-ticket-section">
                                    <div className="auth-container">
                                    <Card
                                        title="Subscriptions"
                                        extra={<Row gutter={[10, 10]} align={"middle"}>
                                            <Col style={{marginBottom: 3}}>
                                                <InfoCircleOutlined/>
                                            </Col>
                                            <Col>
                                                <a target={"_blank"}
                                                   href={`https://help.tecme.io/content/how-do-recurring-services-work`}
                                                   className="text-orange" style={{fontSize: 14}}>
                                                    Read how subscriptions work
                                                </a>
                                            </Col>
                                        </Row>}
                                    >
                                        <Row gutter={[15, 15]}>
                                            {
                                                subscriptionsLoading &&
                                                <>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                </>
                                            }
                                            <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                <Row gutter={[10, 10]}>
                                                {
                                                        subscriptions.length == 0 && subscriptionsLoading == false &&
                                                        <Result
                                                            icon={<InfoCircleOutlined style={{color: SECONDARY_COLOR}}/>}
                                                            title={"You have no subscriptions"}
                                                            subTitle={this.props.user ? "When you subscribe to a recurring service it will be displayed here" : "Create a recurring service to begin getting subscriptions from customers"}
                                                            // extra={
                                                            //     <Button className='button-background'
                                                            //             onClick={() => {
                                                            //                 this.props.history.push(this.props.tech ? ROUTES.ADD_SERVICES : '/');
                                                            //             }}
                                                            //     >
                                                            //         {this.props.user ? "Search Now" : "+ Add Recurring Service"}
                                                            //     </Button>
                                                            // }
                                                        />
                                                    }
                                                    {
                                                        subscriptions.map((record, index) => {
                                                            let tagColor = '';
                                                            let status = record.status.toString().toUpperCase();
                                                            if (record.status == 'past_due')
                                                                tagColor = 'gold';
                                                            else if (record.status == 'trialing') {
                                                                tagColor = 'blue';
                                                                status = `Trial ends ${moment.unix(record.trial_end).format(' MMM Do YYYY')}`;
                                                            } else if (record.status == 'active')
                                                                tagColor = 'green';
                                                            else if (record.status == 'incomplete' || record.status == 'incomplete' || record.status == 'incomplete')
                                                                tagColor = 'red';

                                                            let supportTypeIcon = <AimOutlined style={{fontSize: 20}}/>;
                                                            let supportType = record.metadata.Support_Type ? record.metadata.Support_Type : 'N/A';
                                                            if (supportType.toUpperCase() == 'REMOTE ACCESS')
                                                                supportTypeIcon = <DesktopOutlined style={{fontSize: 20}}/>
                                                            else if (supportType.toUpperCase() == 'VIDEO CALL')
                                                                supportTypeIcon = <VideoCameraOutlined style={{fontSize: 20}}/>

                                                            let techView = <Row align={"middle"} gutter={[5.5]}>
                                                                <Col>
                                                                    {
                                                                        record.metadata.Customer_Profile_Picture && record.metadata.Customer_Profile_Picture != null && record.metadata.Customer_Profile_Picture != 'N/A' ?
                                                                            <Avatar src={record.metadata.Customer_Profile_Picture}/>
                                                                            : <Avatar style={{
                                                                                color: '#f56a00',
                                                                                backgroundColor: '#fde3cf',
                                                                                fontSize: 20
                                                                            }}>{record.metadata.Customer_Name[0].toUpperCase()}</Avatar>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <Text>{capitalizeFirstLetter(record.metadata.Customer_Name)}</Text>
                                                                </Col>
                                                            </Row>

                                                            let customerView = <Row align={"middle"} gutter={[5.5]}>
                                                                <Col>
                                                                    {
                                                                        record.metadata.Technician_Profile_Picture && record.metadata.Technician_Profile_Picture != null && record.metadata.Technician_Profile_Picture != 'N/A' ?
                                                                            <Avatar src={record.metadata.Technician_Profile_Picture}/>
                                                                            : <Avatar style={{
                                                                                color: '#f56a00',
                                                                                backgroundColor: '#fde3cf',
                                                                                fontSize: 20
                                                                            }}>{record.metadata.Technician_Name[0].toUpperCase()}</Avatar>
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                            href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record.metadata.Technician_Hipaa_Id)}>{ capitalizeFirstLetter(record.metadata.Technician_Name)}</Button>
                                                                </Col>
                                                            </Row>;

                                                            let amount = record.items.data[0].plan.amount;
                                                            let interval = record.items.data[0].plan.interval;
                                                            let intervalCount = record.items.data[0].plan.interval_count;
                                                            let pricing = '';
                                                            if (intervalCount == 1)
                                                                pricing = `$${amount / 100}/${interval}`
                                                            else if (intervalCount > 1)
                                                                pricing = `$${amount / 100} every ${intervalCount} ${interval}s`

                                                            return <Col key={`subscription-${index}`} span={24}>
                                                                <Card
                                                                    title={this.props.user ? customerView : techView}
                                                                >
                                                                    <Text ellipsis={{rows: 2}} strong>{record.metadata.Service_Title}</Text>
                                                                    <Row gutter={[15, 15]} className={"mt-2"}>
                                                                        <Col span={24}>
                                                                            <Row gutter={[10, 10]} align={"middle"}>
                                                                                <Col>{supportTypeIcon}</Col>
                                                                                <Col><Text>{supportType}</Text></Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Row gutter={[10, 10]} align={"middle"}>
                                                                                <Col><DollarOutlined style={{fontSize: 20}}/></Col>
                                                                                <Col> <Text>{pricing}</Text></Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Tag color={tagColor}>{status}</Tag>
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={12} lg={0} xl={0}>
                                                                            <Button
                                                                                danger
                                                                                style={{width: '100%'}}
                                                                                onClick={(e) => {
                                                                                    this.cancelSubscriptionModal(record)
                                                                                }}
                                                                            >
                                                                                Cancel Subscription
                                                                            </Button>
                                                                        </Col>
                                                                        {
                                                                            this.props.user &&
                                                                            <Col xs={24} sm={24} md={12} lg={0} xl={0}>
                                                                                <Button
                                                                                    danger
                                                                                    style={{width: '100%'}}
                                                                                    onClick={(e) => {
                                                                                        this.selectedSubscriptionId = record.id;
                                                                                        this.setState({
                                                                                            paymentMethodModalVisible: true
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    Change Payment Method
                                                                                </Button>
                                                                            </Col>
                                                                        }

                                                                    </Row>
                                                                </Card>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>

                                            </Col>

                                            {
                                                // (subscriptions.length > 0 || subscriptionsLoading == true) &&
                                                <>
                                                    <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                        <CustomRenderEmpty description={this.props.user ? "When you subscribe to a recurring service it will be displayed here" : "Create a recurring service to begin getting subscriptions from customers"} extra={""}>
                                                        <Table
                                                            style={{minHeight: 300}}
                                                            loading={subscriptionsLoading}
                                                            columns={[
                                                                {
                                                                    title: 'Short Description',
                                                                    key: 'title',
                                                                    web: true,
                                                                    mobile: true,
                                                                    className: "service-title-column",
                                                                    render: (record) => {
                                                                        return <div style={{width: 150}}>
                                                                            <Text ellipsis={{rows: 1}}>{capitalizeFirstLetter(record.metadata.Service_Title)}</Text>
                                                                        </div>
                                                                    },
                                                                },

                                                                {
                                                                    title: this.props.user ? 'Technician' : 'Customer',
                                                                    // dataIndex: 'user',
                                                                    key: 'user',
                                                                    web: true,
                                                                    mobile: true,
                                                                    render: (record) => {
                                                                        let techView = <Row align={"middle"}>
                                                                            <Col>
                                                                                {
                                                                                    record.metadata.Customer_Profile_Picture && record.metadata.Customer_Profile_Picture != null && record.metadata.Customer_Profile_Picture != 'N/A' ?
                                                                                        <Avatar src={record.metadata.Customer_Profile_Picture}/>
                                                                                        : <Avatar style={{
                                                                                            color: '#f56a00',
                                                                                            backgroundColor: '#fde3cf',
                                                                                            fontSize: 20
                                                                                        }}>{record.metadata.Customer_Name[0].toUpperCase()}</Avatar>
                                                                                }
                                                                            </Col>
                                                                            <Col>
                                                                                <Text style={{padding: '4px 15px'}}>{capitalizeFirstLetter(record.metadata.Customer_Name)}</Text>
                                                                            </Col>
                                                                        </Row>

                                                                        let customerView = <Row align={"middle"}>
                                                                            <Col>
                                                                                {
                                                                                    record.metadata.Technician_Profile_Picture && record.metadata.Technician_Profile_Picture != null && record.metadata.Technician_Profile_Picture != 'N/A' ?
                                                                                        <Avatar src={record.metadata.Technician_Profile_Picture}/>
                                                                                        : <Avatar style={{
                                                                                            color: '#f56a00',
                                                                                            backgroundColor: '#fde3cf',
                                                                                            fontSize: 20
                                                                                        }}>{record.metadata.Technician_Name[0]}</Avatar>
                                                                                }
                                                                            </Col>
                                                                            <Col>
                                                                                <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                        href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record.metadata.Technician_Hipaa_Id)}>{record.metadata.Technician_Name}</Button>
                                                                            </Col>
                                                                        </Row>

                                                                        return this.props.user ? customerView : techView;
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Support Type',
                                                                    key: 'supportType',
                                                                    // dataIndex: 'supportType',
                                                                    web: true,
                                                                    mobile: false,
                                                                    // sorter: {
                                                                    //     compare: (a, b) => a.supportType.localeCompare(b.supportType),
                                                                    // },
                                                                    render: (record) => {
                                                                        return <Text>{record.metadata.Support_Type ? record.metadata.Support_Type : 'N/A'}</Text>
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Status',
                                                                    key: 'status',
                                                                    // dataIndex: 'status',
                                                                    web: true,
                                                                    mobile: false,
                                                                    render: (record) => {
                                                                        let tagColor = '';
                                                                        let status = record.status.toString().toUpperCase();
                                                                        if (record.status == 'past_due')
                                                                            tagColor = 'gold';
                                                                        else if (record.status == 'trialing') {
                                                                            tagColor = 'blue';
                                                                            status = `Trial ends ${moment.unix(record.trial_end).format(' MMM Do YYYY')}`;
                                                                        } else if (record.status == 'active')
                                                                            tagColor = 'green';
                                                                        else if (record.status == 'incomplete' || record.status == 'incomplete' || record.status == 'incomplete')
                                                                            tagColor = 'red';

                                                                        return <Tag color={tagColor}>{status}</Tag>
                                                                    },
                                                                    // sorter: {
                                                                    //     compare: (a, b) => a.status.localeCompare(b.status),
                                                                    // },
                                                                },

                                                                {
                                                                    title: 'Price',
                                                                    key: 'price',
                                                                    // dataIndex: 'bookingDisplayStatus',
                                                                    web: true,
                                                                    mobile: false,
                                                                    // sorter: {
                                                                    //     compare: (a, b) => a.bookingDisplayStatus.localeCompare(b.bookingDisplayStatus),
                                                                    // },
                                                                    render: (record) => {
                                                                        let amount = record.items.data[0].plan.amount;
                                                                        let interval = record.items.data[0].plan.interval;
                                                                        let intervalCount = record.items.data[0].plan.interval_count;
                                                                        let pricing = '';
                                                                        if (intervalCount == 1)
                                                                            pricing = `$${amount / 100}/${interval}`
                                                                        else if (intervalCount > 1)
                                                                            pricing = `$${amount / 100} every ${intervalCount} ${interval}s`

                                                                        return <Text>{pricing}</Text>
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Actions',
                                                                    key: 'action',
                                                                    className: "subscriptions-action-column",
                                                                    render: (text, record, index) => {
                                                                        return <Row gutter={[10, 10]}>
                                                                            <Col span={24}>
                                                                                <Button
                                                                                    danger
                                                                                    style={{width: '100%'}}
                                                                                    onClick={(e) => {
                                                                                        this.cancelSubscriptionModal(record);
                                                                                    }}
                                                                                >
                                                                                    Cancel Subscription
                                                                                </Button>
                                                                            </Col>
                                                                            {
                                                                                this.props.user &&
                                                                                <Col span={24}>
                                                                                    <Button
                                                                                        danger
                                                                                        style={{width: '100%'}}
                                                                                        onClick={(e) => {
                                                                                            this.selectedSubscriptionId = record.id;
                                                                                            this.setState({
                                                                                                paymentMethodModalVisible: true
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        Change Payment Method
                                                                                    </Button>
                                                                                </Col>
                                                                            }


                                                                        </Row>
                                                                    },
                                                                },
                                                            ]}
                                                            dataSource={subscriptions}/>
                                                            </CustomRenderEmpty>
                                                    </Col>
                                                </>
                                            }
                                        </Row>

                                        {
                                            subscriptions.length > 0 &&
                                            <Alert message="Please refresh your page to view new subscriptions" type="info"/>
                                        }

                                    </Card>
                                    </div>
                                </Col> */}
                            </Row>
                    </div>
                </Content>
            </Fragment>
        )
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(HomePage);
