import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION, META_IMAGE } from '../../../../../../utils/constants'
import { Button, Col, Form, Input, Layout, Row, Space, Typography, notification } from 'antd'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UtilityHeader from '../../../../../components/utility-header'
import routes from '../../../../../../utils/routes'
import {Link} from 'react-router-dom'
import SignUpImage from './assets/images/signup-img-2.png'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useRef } from 'react'
import { useEffect } from 'react'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { getUserProfile } from '../../../../../../utils/helper'
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordStrengthBar from 'react-password-strength-bar';
import { FirebaseHelper } from "../../../../../../utils/firebase-helper";
import { isBrowser, isTablet, isMobileOnly } from 'react-device-detect';
import './css/styles.scss'
import ClientOnboarding from '../../../../customer/client-onboarding';


const {Content} = Layout
const {Paragraph, Title, Text} = Typography

const SignUp = () => {
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("")
    const [error, setError] = useState();
    const [isVerified, setIsVerified] = useState(false);
    const [status, setStatus] = useState(false);
    const [passwordScore, setPasswordScore] = useState(0);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [btn, setBtn] = useState(false)
    const [emailExist, setEmailExist] = useState(false);
    const [IsFirstName, setIsFirstName] = useState(false);
    const [IsLastName, setIsLastName] = useState(false);
    let history = useHistory();
    const captchaRef = useRef(null)

    const onFinish = async (values) => {
        if (!isVerified) {
            setStatus(true)
            return
        }
        if (!btn || emailExist || loading || IsFirstName || IsLastName)
            return;
        setLoading(true);

        let fcmToken = await FirebaseHelper.getFcmToken();
        let deviceType = 'Desktop';
        let frontEnd = 'Website';
        if (isMobileOnly) {
            deviceType = 'Phone';
        }
        else if (isTablet) {
            deviceType = 'Tablet';
        }
        ApiBaseHelper.post({
            url: `register`, payload: {
                "firstName": values.fname,
                "lastName": values.lname,
                "email": values.email,
                "password": values.password,
                "phone": values.phoneNumber,
                "fcm_token": fcmToken,
                "device_type": deviceType,
                "front_end": frontEnd,
            }
        }).then(async (res) => {
            await getUserProfile({
                orgId: res.data.data.orgId,
                token: res.data.data.token,
                tech: res.data.data.technician,
                dispatch: dispatch,
                redirectTarget: "_self",
                touchPoint: res.data.data.touchPoint,
                isSendVerificationEmail: true,
                role: res.data.data.role,
                organization: res.data.data.organization,
                email: values.email,
                password: values.password,
                newSignup: true
            });
        }).catch((e) => {
            setLoading(false)
            openNotification({ message: 'TecMe', description: 'Something went wrong, please try again later.' })
        })
    };


    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const emailChangeHandler = event => {
        let val = event.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false
        setEmailExist(false)
        if (val) {
            ApiBaseHelper.get({
                url: "web/account?email=" + event.target.value
            }).then((res) => {
                if (res.data.data.length != 0) {
                    setEmailExist(true)
                } else {
                    setEmailExist(false)
                }

            }).catch((e) => {
                console.log(e)
            })
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <>
        <MetaTags>
                <title >Create an Account - TecMe</title>
                <meta name="description" content={"TecMe is your nationwide network of IT technicians to solve any IT problem! Create an account to join the network never deal with annoying IT problems again."} />
                <meta property="og:title" content={`Create an Account - TecMe`} />
                <meta property="og:description" content={`TecMe is your nationwide network of IT technicians to solve any IT problem! Create an account to join the network never deal with annoying IT problems again.`} />
                <meta property="og:image" content={META_IMAGE} />
                <meta name="prerender-status-code" content="200"/>
                <meta name="robots" content="index, follow" />
        </MetaTags>
        <Content className="main">
            <div className="page" style={{paddingBottom:0}}>
                <div className="sign-up-section">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <UtilityHeader />
                            <div className="signup-form-section">
                                <div>
                                    <div className="heading-section">   
                                        <Title level={1}>Join Us Today!</Title>
                                        <Paragraph style={{color:"#787C81", fontWeight:500}}>Let’s create your account to get started.</Paragraph>
                                    </div>
                                    <Form
                                        name="normal_login"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                    >
                                        <Row gutter={12}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Paragraph style={{marginBottom:4, color:"#202223"}}>First Name</Paragraph>
                                                <Form.Item
                                                name="fname"
                                                    onChange={(event) => {
                                                        event.target.value.match(/^[A-Za-z]*$/) ? setIsFirstName(false) : setIsFirstName(true)
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                
                                                            message: 'Please enter your first name',
                                                        },
                                                        {
                
                                                            max: 20,
                                                            message: 'First name should not be more than 20 characters',
                                                        },
                                                    ]}
                                                    style={{marginBottom:12}}
                                                >
                                                    <Input className='sign-up-input' />
                                                </Form.Item>
                                                {IsFirstName && <p style={{ padding: 0, marginBottom: 16, color: '#F04A22' }}>Numbers, spaces and special characters are not allowed</p>}
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Paragraph style={{marginBottom:4, color:"#202223"}}>Last Name</Paragraph>
                                                <Form.Item
                                                    name="lname"
                                                    onChange={(event) => {
                                                        event.target.value.match(/^[A-Za-z]*$/) ? setIsLastName(false) : setIsLastName(true)
                                                     }}
                                                    rules={[{ required: true, message: 'Please enter your last name' }]}
                                                    style={{marginBottom:12}}
                                                >
                                                    <Input className='sign-up-input' />
                                                </Form.Item>
                                                {IsLastName && <p style={{ padding: 0, marginBottom: 16, color: '#F04A22' }}>Numbers, spaces and special characters are not allowed</p>}
                                            </Col>
                                        </Row>
                                        <>
                                            <Paragraph style={{marginBottom:4, color:"#202223"}}>Email</Paragraph>
                                            <Form.Item
                                                name="email"
                                                style={{marginBottom:12}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your email',
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Please enter a valid email'
                                                    }
                                                ]}
                                            >
                                                <Input 
                                                    className='sign-up-input' 
                                                    autoComplete='off'
                                                    onChange={emailChangeHandler}/>
                                            </Form.Item>
                                            {emailExist && <p style={{ padding: 0, marginBottom: 16, color: 'red' }}>The email entered already has an account</p>}
                                        </>
                                        <>
                                            <Paragraph style={{marginBottom:4, color:"#202223"}}>Phone Number</Paragraph>
                                            <Form.Item
                                                name="phoneNumber"
                                                rules={[
                                                    {
                                                                                                    
                                                        pattern: new RegExp(/^[0-9+]{3}[0-9+]{3}[0-9]{4}$/),
                                                        message: "Phone number should be 10 digits"
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className='sign-up-input' 
                                                    autoComplete='off'
                                                />
                                            </Form.Item>
                                        </>
                                        <>
                                            <Paragraph style={{marginBottom:4, color:"#202223"}}>Password</Paragraph>
                                            <Form.Item
                                                name="password"
                                                style={{marginBottom:12}}
                                                help={!btn === true && password.length >= 8 ? <p style={{ color: 'red' }}>Enter strong password</p> : null}
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 0,
                                                        message: 'Please enter new password',
                                                    },
                                                    {
            
                                                        min: 8,
                                                        message: 'Password should be at least 8 characters or more',
                                                    },
                                                ]}
                                            >
                                                <Input.Password 
                                                    type="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    autoComplete='off'
                                                    className='sign-up-input' />
                                            </Form.Item>
                                            <PasswordStrengthBar
                                                password={password}
                                                onChangeScore={(passwordValue, feedback) => {
                                                    if (passwordValue == 3 || passwordValue == 4) {
                                                        setBtn(true)
                                                    } else {
                                                        setBtn(false)
                                                    }
                                                    setPasswordScore({
                                                        passwordValue
                                                    })
                                                }}
                                            />
                                        </>
                                        <Form.Item>
                                        {error && <p style={{ color: "red" }}>{error}</p>}
                                            { isVerified == false &&
                                                <ReCAPTCHA style={{ marginBottom: '1rem' }}
                                                    onChange={(value) => {
                                                        // console.log("Captcha  Value", value)
                                                        setIsVerified(true)
                                                        setStatus(false)
                                                        isVerified == true && captchaRef.current.reset()
                                                    }}
                                                    sitekey={process.env.REACT_APP_CAPTCHA_KEY != undefined ? process.env.REACT_APP_CAPTCHA_KEY : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                                    ref={captchaRef}
                                                />}
                                            {status == true && < p style={{ color: 'red' }}>Please verify you're human.</p>}
                                            {
                                            isVerified == true &&
                                                <div style={{ color: 'green', marginBottom: '1rem' }}><CheckCircleOutlineIcon fontSize="large" /> You're human
                                                </div>
                                            }
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="sign-up-button" loading={loading}>
                                                Create Account
                                            </Button>
                                        </Form.Item>
                                        <Paragraph style={{color:"#787C81", fontWeight:500, marginTop:40, textAlign:"center"}}>By signing up, I agree to the <a style={{color:"#787C81", textDecoration:"underline"}} href='https://tecme.io/privacy-policy/' target='_blank'>Privacy Policy</a> and <br /> <a href='https://tecme.io/terms-of-service/' target='_blank' style={{color:"#787C81", textDecoration:"underline"}} to={routes.TERM_OF_SERVICES}>Terms of Service</a>.</Paragraph>
                                    </Form>
                                </div>
                                <div className="other-links-section">
                                    <Space>
                                        <Text style={{color: '#787C81', fontSize:16, fontWeight:500}}>Already have an account?</Text>
                                        <Link to={routes.LOGIN} style={{color: '#1A1D1F', fontSize:16, fontWeight:500, textDecoration:"none"}}>Sign in</Link>
                                    </Space>
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={12} xl={12}>
                            <div className="signup-right-section">
                                <div className="signup-testimony">
                                    <Paragraph style={{fontSize:24, color:"#000000", fontWeight:500}}>"TecMe has been a game-changer for my business. The process is seamless, and the IT professionals are skilled and reliable. Highly recommend!"</Paragraph>
                                    <Paragraph style={{color:"#727272", fontSize:14, marginTop:20}}>• Sarah, CEO of Stratos</Paragraph>
                                </div>
                                <div className="signup-image">
                                    <img src={SignUpImage} alt="sign up image" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Content>
    </>
  )
}

export default SignUp