import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {updateUser, updateTech, updateEncryptedPassword} from '../../../../../../redux/reducers/app';
import classes from './css/sign-in.module.css';
import {
    Layout,
    Form,
    Input,
    Button,
    Alert,
    Checkbox,
    notification,
    Col,
    Row,
    Typography,
    Space
} from 'antd';
import {ApiBaseHelper} from "../../../../../../utils/api-base-helper";
import {useHistory, useLocation} from "react-router-dom";
import {EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, UserOutlined, LockOutlined, WarningOutlined} from '@ant-design/icons';
import {useCookies} from 'react-cookie';
import 'antd/dist/antd.css'
import ROUTES from '../../../../../../utils/routes'
import {FirebaseHelper} from "../../../../../../utils/firebase-helper";
import {encryptString, getUserProfile} from "../../../../../../utils/helper";
import {isBrowser, isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import MetaTags from "react-meta-tags";
import {META_DESCRIPTION, META_IMAGE} from "../../../../../../utils/constants";
import { Link } from 'react-router-dom';


const {Title, Paragraph, Text} = Typography;
const {Content} = Layout;
const {useForm} = Form

const Login = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [form] = useForm()
    const [cookies, setCookie, removeCookie] = useCookies(['email', 'password']);
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const isLoggedIn = user || tech
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(cookies.email ? true : false);
    const [error, setError] = useState();
    const [errorsStatus, setErrorStatus] = useState(false);
    let history = useHistory();
    const {search} = useLocation()

    const params = new URLSearchParams(search)
    const isAccessDenied = props.access === false
    const isGuest = props?.isGuest


    const onFinish = async (values) => {
        dispatch(updateEncryptedPassword(encryptString(values.password))) 
        if (check) {
            setCookie('email', values.email, {path: '/'});
            setCookie('password', values.password, {path: '/'});
            setCheck(true)
        } else if (!check) {
            removeCookie("email")
            removeCookie("password")
        }
        if (loading) {
            return
        }
        setLoading(true);

        let fcmToken = props.fcmToken;
        let deviceType = 'Desktop';
        let frontEnd = 'Website';
        if (isMobileOnly) {
            deviceType = 'Phone';
        } else if (isTablet) {
            deviceType = 'Tablet';
        }

        if (!isGuest){
            ApiBaseHelper.post({
                url: `login`,
                payload: {
                    "email": values.email,
                    "password": values.password,
                    "fcm_token": fcmToken,
                    "device_type": deviceType,
                    "front_end": frontEnd,
                }
            }).then(async (res) => {
                await getUserProfile({
                    orgId: res.data.data.orgId,
                    token: res.data.data.token,
                    tech: res.data.data.technician,
                    dispatch: dispatch,
                    redirectTarget: "_self",
                    touchPoint: res.data.data.touchPoint,
                    redirect: isAccessDenied ? false : true,
                    role: res.data.data.role,
                    organization: res.data.data?.organization,
                    email: values.email,
                    password: values.password,
                })
                setErrorStatus(false)
                setError("")
                setLoading(false)
    
            }).catch((e) => {
                setLoading(false)
                setErrorStatus(true)
                setError("Email or Password is invalid");
                openNotification({message: 'TecMe', description: 'Login Failed! Email or Password is invalid',})
    
            })
        } else {
            ApiBaseHelper.post({
                url: `login`,
                payload: {
                    "email": values.email,
                    "password": values.password,
                    "fcm_token": fcmToken,
                    "device_type": deviceType,
                    "front_end": frontEnd,
                }
            }).then((res) => {
                setErrorStatus(false)
                setError("")
                return ApiBaseHelper.post({
                    url: `user-management/accept-invite/${props.ocaId}/${props.orgId}`,
                    headers: {
                        Authorization: 'Bearer ' + res.data.data.token,
                    },
                })
            }).then((acceptRes) => {
                if(acceptRes){
                    return ApiBaseHelper.post({
                        url: `login`,
                        payload: {
                            "email": values.email,
                            "password": values.password,
                            "org_id": props?.orgId,
                            "switch": true,
                            "device_type": deviceType,
                            "front_end": frontEnd,
                        }
                    })
                }
                return ApiBaseHelper.post({
                    url: `login`,
                    payload: {
                        "email": values.email,
                        "password": values.password,
                        "org_id": props?.orgId,
                        "switch": true,
                    }
                })
            }).then(async(loginRes) => {
                await getUserProfile({
                    orgId: loginRes.data.data.orgId,
                    token: loginRes.data.data.token,
                    tech: loginRes.data.data.technician,
                    dispatch: dispatch,
                    redirectTarget: "_self",
                    touchPoint: loginRes.data.data.touchPoint,
                    redirect: isAccessDenied ? false : true,
                    role: loginRes.data.data.role,
                    organization: loginRes.data.data?.organization,
                    email: values.email,
                    password: values.password,
                })
                setErrorStatus(false)
                setError("")
                setLoading(false)
                history.push(ROUTES.DASHBOARD)
            }).catch((e) => {
                setLoading(false)
                setErrorStatus(true)
                setError("Email or Password is invalid");
                openNotification({message: 'TecMe', description: 'Something went wrong. Please try again',})
    
            })
        }
    };

    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (isLoggedIn)
            history.push(ROUTES.DASHBOARD)
    }, [])

    useEffect(() => {
        if (location?.state?.email) {
            form.setFieldsValue({
                email: location?.state?.email
            })
        }
    }, [location?.state?.email])

    useEffect(() => {
        if(isGuest){
            form.setFieldsValue({
                email: props.email,
            })
        }
    }, [isGuest])

    return (
        <Content className="main">
            <MetaTags>
                <title>Login - TecMe </title>
                <meta name="description"
                      content={"Log in to TecMe, your nationwide network of IT technicians. Find IT solutions for all your problems from small to big."}/>
                <meta property="og:title" content={`Login - TecMe`}/>
                <meta property="og:description"
                      content={`Log in to TecMe, your nationwide network of IT technicians. Find IT solutions for all your problems from small to big.`}/>
                <meta property="og:image" content={META_IMAGE}/>
                <meta name="prerender-status-code" content="200"/>
                <meta name="robots" content="index, follow"/>
            </MetaTags>
            <div>
                {/* <UtilityHeader /> */}
                <div className={classes["login-section"]}>
                    <div className={classes['login-container']}>
                        <div className={classes['sign-form']}>
                            <div className={classes.Title}>
                                {
                                    isAccessDenied ? (
                                        <>
                                            <Space className='mb-3' align='end' size={15}>
                                                <WarningOutlined style={{color:"#F04A22", fontSize:40, position:"relative", top:-6}}/>
                                                <Title level={2} style={{marginBottom:0}}>Access Denied</Title>
                                            </Space>
                                            <Paragraph>You are currently unable to view this page. <br /> Please log in to your account to gain access to this page.</Paragraph>
                                        </>
                                    ) :
                                    <>
                                        <Title style={{padding: 0, marginBottom: 25, color: '#1A1D1F'}}>Welcome back!</Title>
                                        {
                                            isGuest && (
                                                <Paragraph className={classes["guest-subtitle"]}>Please log in to your TecMe account in order to accept the invitation from the {props?.company} team.</Paragraph>
                                            )
                                        }
                                    </>
                                }
                            </div>
                            <Form
                                form={form}
                                name="normal_login"
                                className={classes['login-form']}
                                initialValues={{remember: true}}
                                onFinish={onFinish}
                                layout='vertical'
                            >
                                <Paragraph style={{marginBottom:4, color: "#202223"}}>Email</Paragraph>
                                <Form.Item
                                    name="email"
                                    initialValue={cookies ? cookies.email : null}
                                    rules={[{
                                        required: true,
                                        type: 'email',
                                        message: 'Please enter a valid email address'
                                    }]}
                                >
                                    <Input 
                                        className={classes['login-input']}  
                                        type="email"
                                    />
                                </Form.Item>
                                <Paragraph style={{marginBottom:4, color: "#202223"}}>Password</Paragraph>
                                <Form.Item
                                    name="password"
                                    initialValue={cookies ? cookies.password : null}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            message: 'Please enter a valid password',
                                        },
                                        {

                                            min: 8,
                                            message: 'Password should be at least 8 characters or more',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        className={classes['login-input']}
                                        type="password"
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                        // className="site-form-item-icon"
                                    />
                                </Form.Item>
                                {errorsStatus ? <Alert message={error} type="error"/> : null}

                                <Row justify={"space-between"} align={"middle"} className="mb-4">
                                    <Col>
                                        <Button 
                                            className='border-0 bg-white' 
                                            style={{color: 'rgba(0, 0, 0, 0.85)', fontSize:14, padding: 0, fontWeight:500}} 
                                            type="link"
                                            htmlType="button"
                                            onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}>
                                            Forgot Password?
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Form.Item name="remember" noStyle>
                                            <Checkbox 
                                                style={{color: 'rgba(0, 0, 0, 0.85)', fontSize:14, fontWeight:500}} 
                                                checked={check}
                                                onChange={event => setCheck(event.target.checked)}>
                                                Remember me
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Button 
                                            type="primary" 
                                            loading={loading} 
                                            htmlType="submit"
                                            className={classes['login-btn']}>
                                            {!isGuest ? "Sign in" : "Accept Invitation"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className={classes['sign-up-cta']}>
                            <Space>
                                <Text style={{color: '#787C81', fontSize:16, fontWeight:500}}>Don't have an account?</Text>
                                <Link to={ROUTES.SIGN_UP} style={{color: '#1A1D1F', fontSize:16, fontWeight:500, textDecoration:"none"}}>Sign up</Link>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
        </Content>

    );
}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(Login);