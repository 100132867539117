import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../utils/constants'
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs'
import ROUTES from '../../../../utils/routes'
import { Badge, Button, Input, Layout, List, Popover, Space, Table, Tag, Typography } from 'antd'
import ActionIcon from  './assets/icons/action-icon.svg'
import './assets/css/styles.scss'
import { DeleteOutlined, EditOutlined, EyeOutlined, FolderOutlined, FolderViewOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import moment from "moment";
import { capitalizeFirstLetter, getFromNowDateFormat, getTableTimeFormat } from '../../../../utils/helper'
import { convertBudget } from '../../common/private/job-post/utils/helper'
import CustomRenderEmpty from '../../../components/custom-render-empty'
import useWindowSize from '../../../../hooks/useWindowSize'

const { Content } = Layout
const {Title, Paragraph} = Typography
const {Search} = Input


const statusColor = (status) => {
    switch(status) {
        case 'Active':
            return 'success'
        case 'Draft':
            return 'warning'
        case 'Closed':
            return 'default'
        default:
            return 'default'
    }
}

const JobPostings = () => {
    const user = useSelector(state => state.user)
    const history = useHistory()
    const [loadingJobs, setLoadingJobs] = useState(false)
    const [jobPosts, setJobPosts] = useState([])
    const [filteredJobPosts, setFilteredJobPosts] = useState([])
    const {windowWidth} = useWindowSize()

    const activeJobPostPopoverContent = (item) => {
        return(
            <div className="job-posting-popover-content">   
                {/* <Space className='popover-action-item' align='center' onClick={() => history.push(ROUTES.JOB_APPLICATIONS)}>
                    <FolderViewOutlined />
                    <Paragraph style={{marginBottom:0}}>View applications</Paragraph> 
                </Space>    */}
                <Space className='popover-action-item' 
                align='center' 
                onClick={(e) => {
                    e.stopPropagation()
                    history.push(ROUTES.MANAGE_JOB_POSTING.replace(":id", item?.id))
                }}>
                    <EyeOutlined />
                    <Paragraph style={{marginBottom:0}}>View Job Post</Paragraph> 
                </Space>
                <Space 
                    className='popover-action-item' align='center'
                    onClick={(e) => { 
                        e.stopPropagation()
                        history.push(ROUTES.EDIT_JOB_POSTING.replace(":id", item?.id))
                    }}
                >
                    <EditOutlined />
                    <Paragraph style={{marginBottom:0}}>Edit Job</Paragraph> 
                </Space>
                {/* <Space className='popover-action-item' align='center'>
                    <DeleteOutlined />
                    <Paragraph style={{marginBottom:0}}>Delete job post</Paragraph> 
                </Space> */}
            </div>
        )
    }

    const handleJobPostSearch = (e) => {
        const value = e.target.value.toLowerCase()
        const filteredData = jobPosts.filter((item) => {
            return item.Title.toLowerCase().includes(value)
        })
        setFilteredJobPosts(filteredData)
    }

    const fetchJobPostings = () => {
        setLoadingJobs(true)
        ApiBaseHelper.get({
            url: `job-post/client`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobPosts(res?.data?.data.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)))
            setLoadingJobs(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJobs(false)
        })       
    }

    useEffect(() => {
        fetchJobPostings()
    }, [])

    useEffect(() => {
        setFilteredJobPosts(jobPosts)
    }, [jobPosts])

    const jobPostingsColumns = [
        {
            title: 'Job Title',
            dataIndex: 'job-title',
            key: 'job-title',
            width:"40%",
            render:(_, record) => {
                return(
                    <Space direction="vertical">
                        <Paragraph style={{margin:0, fontWeight:500}}>{capitalizeFirstLetter(record?.Title)}</Paragraph>
                        <Paragraph style={{margin:0}}>{convertBudget(record?.Budget, record?.Payment_Type)}</Paragraph>
                    </Space>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a.Title) return a.Title.localeCompare(b.Title)
                },
            },
        },
        {
            title:'Applications',
            dataIndex: 'applications',
            key: 'applications',
            render:(_, record) => {
                return(
                    <Space size={40}>
                        <Space direction="vertical" className='statistics-item'>
                            <Paragraph style={{margin:0, fontWeight:500}}>{record?.custom_data?.Applicants}</Paragraph>
                            {/* <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.45)"}}>Applications</Paragraph> */}
                        </Space>
                        {/* <Space direction="vertical" className='statistics-item'>
                            <Paragraph style={{margin:0, fontWeight:500}}>{record?.messaged}</Paragraph>
                            <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.45)"}}>Messaged</Paragraph>
                        </Space>
                        <Space direction="vertical" className='statistics-item'>
                            <Paragraph style={{margin:0, fontWeight:500}}>{record?.hired}</Paragraph>
                            <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.45)"}}>Hired</Paragraph>
                        </Space> */}
                    </Space>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a.custom_data?.Applicants) return a.custom_data?.Applicants.localeCompare(b.custom_data?.Applicants)
                },
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <Tag color={statusColor(record?.Status)} >{record.Status}</Tag>
            ),
            sorter: {
                compare: (a, b) => {
                    if(a.Status) return a.Status.localeCompare(b.Status)
                },
            },
        },
        {
            title: 'Published',
            key: 'published',
            render: (_, record) => (
                <Paragraph style={{margin:0, fontWeight:500}}>{getTableTimeFormat(record?.CreatedDate)}</Paragraph>
            ),
            sorter: {
                compare: (a, b) => {
                    if(a.CreatedDate) return a.CreatedDate.localeCompare(b.CreatedDate)
                },
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            // width:"30%",
            render: (_, record) => (
            <Space className='table-action-ctn' wrap>
                {/* <Button type='primary' onClick={() => history.push(ROUTES.JOB_APPLICATIONS)}>View Proposals</Button> */}
                <Popover content={activeJobPostPopoverContent(record)}  trigger="click" placement='bottom' overlayClassName="job-posting-popover" arrowPointAtCenter>
                    <img src={ActionIcon} alt="action icon" onClick={
                        (e) => {
                            e.stopPropagation()
                        }}/>  
                </Popover>
            </Space> 
            ),
        },
    ];

    return (
        <>
            <MetaTags>
                <title>Job Postings - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className='page'>
                    <div className="page-header">
                        <PageHeaderWrapper
                            routes={[
                                {
                                    breadcrumbName: 'Job Postings',
                                },
                            ]}
                        />
                        <Button type="primary" className="btn-add-job-posting" onClick={() => history.push(ROUTES.ADD_JOB_POSTING)}>Post a New Job</Button>
                    </div>
                    <div className="auth-container">
                        <div className="auth-page-ctn job-postings-ctn">
                            <div className="job-postings-header">
                                <div>
                                    <Title level={3} style={{color:"#000", fontWeight:500, fontSize:20}}>Job Postings</Title>
                                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", marginBottom:0}}>Here you can view and manage all your job postings.</Paragraph>
                                </div>
                                {
                                    jobPosts.length > 0 &&
                                    <Search 
                                        placeholder="Search job posts" 
                                        className="search-job-postings" 
                                        onChange={handleJobPostSearch}
                                    />
                                }
                            </div>
                            <div className="job-postings-table">
                                <CustomRenderEmpty
                                    description="You have not posted any jobs yet."
                                    extra={
                                        <Button onClick={() => history.push(ROUTES.ADD_JOB_POSTING)}>Post a Job</Button>
                                    }
                                >
                                    {
                                        windowWidth > 991 ?
                                        <Table 
                                            columns={jobPostingsColumns} 
                                            dataSource={filteredJobPosts} 
                                            loading={loadingJobs} 
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => history.push(ROUTES.MANAGE_JOB_POSTING.replace(":id", record.id))
                                                };
                                            }}
                                            // scroll up on page change
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                                pageSize: 10,
                                            }}
                                        /> :
                                        <List
                                            dataSource={filteredJobPosts}
                                            loading={loadingJobs}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                                pageSize: 10,
                                            }}
                                            renderItem={
                                                item => {
                                                    return(
                                                    <List.Item key={item?.id}>
                                                        <div 
                                                            className="job-post-list-item"
                                                            onClick={() => history.push(ROUTES.MANAGE_JOB_POSTING.replace(":id", item?.id))}
                                                        >
                                                            <div className="job-post-list-item-content">
                                                                <Title level={5} style={{marginBottom:4}}>{capitalizeFirstLetter(item?.Title)}</Title>
                                                                <Paragraph style={{marginBottom:4}}>{convertBudget(item?.Budget, item?.Payment_Type)}</Paragraph>
                                                                <Paragraph style={{marginBottom:4, color:"rgba(0, 0, 0, 0.45)"}}>{getTableTimeFormat(item?.CreatedDate)}</Paragraph>
                                                                <Space wrap>
                                                                    <Badge status={statusColor(item?.Status)} text={item?.Status} />
                                                                    <Space>
                                                                        <span className='dot'>•</span>
                                                                        <Paragraph style={{marginBottom:0, fontSize:14}}>{item?.custom_data?.Applicants} Application{item?.custom_data?.Applicants > 1 ? 's' : ''} </Paragraph>
                                                                    </Space>
                                                                </Space>
                                                            </div>
                                                            <div className='job-post-list-item-cta'>
                                                                <Popover content={activeJobPostPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="job-posting-popover" arrowPointAtCenter>
                                                                    <img src={ActionIcon} 
                                                                    alt="action icon" 
                                                                    onClick={
                                                                        (e) => {
                                                                            e.stopPropagation()
                                                                        }}/>  
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            }
                                        /> 
                                    }
                                </CustomRenderEmpty>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default JobPostings