import React, { useEffect, useState } from 'react'
import './assets/css/styles.scss'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { MetaTags } from 'react-meta-tags'
import ROUTES from '../../../../../utils/routes'
import { Avatar, Badge, Button, Col, Empty, Layout, List, Row, Space, Typography} from 'antd'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useHistory, useParams, Link } from 'react-router-dom'
import ActiveUser from '../../../common/private/dashboard/components/active-user'
import PageFallback from '../../../../components/page-fallback'
import { EnvironmentOutlined, ExportOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { addSubdomainToBaseUrl, capitalizeFirstLetter, getFromNowDateFormat } from '../../../../../utils/helper'
import MessagesRightPaneView from '../../../common/private/real-time-messaging/components/messages-right-pane-view'
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import { getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import RightArrow from '../../../../../assets/images/right-arrow.svg'
import JoinRequests from '../itc-profile/components/join-requests'
import { LoadingTechCards } from '../create-itc/components/tech-team'
import { Tooltip } from '@mui/material'
import { getAlgoliaIndex } from '../../../common/public/instant-search/utils/helper'
import axios from 'axios'

const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const { Content } = Layout
const {Title, Paragraph, Text} = Typography

const ItcDashboard = () => {

    const tech = useSelector(state => state.tech)
    const [ loadingContracts, setLoadingContracts ] = useState(false)
    const [ contractData, setContractData ] = useState([])
    const [ ticketData, setTicketData ] = useState([])
    const [ loadingDetails, setLoadingDetails ] = useState(false)
    const [ itcDetails, setItcDetails ] = useState({})
    const [ loadingGroupConversation, setLoadingGroupConversation ] = useState(false)
    const [ groupConversations, setGroupConversations ] = useState(null)
    const [techTeam, setTechTeam] = useState([])
    const [loadingTeam, setLoadingTeam] = useState(false)
    const [loadingTechProfiles, setLoadingTechProfiles] = useState(false)
    const [techProfiles, setTechProfiles] = useState([])
    const [activeTechs, setActiveTechs] = useState([])

    const history = useHistory()
    const params = useParams()
    
    const getItcDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `itc/${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const fetchContracts = () => {
        setLoadingContracts(true)
        ApiBaseHelper.get({
            url: `contract/itc-contracts?itc=${params?.id}`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            const sortedContractData =  [...res?.data.data]
            sortedContractData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setContractData(sortedContractData)
            setLoadingContracts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContracts(false)
        })       
    }

    const fetchTickets = () => {
        ApiBaseHelper.get({
            url: `ticket?role=${tech ? 'technician' : 'customer'}&itc=${params.id}`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            const sortedTicketData =  [...res?.data.data]
            sortedTicketData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setTicketData(sortedTicketData)
        }).catch((error) => {
            console.error(error)
        })       
    }

     // Function to get and set group conversations for the commpany
     const getAndSetGroupCoversation = async () => {
        setLoadingGroupConversation(true)
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const conversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            conversations.push({ ...item});
          }
        });
        setGroupConversations(conversations.find((c) => c.group.toLowerCase() === (itcDetails?.Company_Name?.toLowerCase())))
        setLoadingGroupConversation(false)
    }

    const fetchTechTeam = () => {
        setLoadingTeam(true)
        ApiBaseHelper.get({
            url: `itc/member/all?itc=${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setTechTeam(res?.data?.data)
            setLoadingTeam(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTeam(false)
        })       
    }

     // fetch tech profiles
     const fetchTechProfiles = async () => {
        setLoadingTechProfiles(true)
        try {
            const response = await axios.get(
              `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
              {
                headers: {
                  'X-Algolia-API-Key': algoliaSearchKey,
                  'X-Algolia-Application-Id': algoliaAppID,
                },
                params: {
                  hitsPerPage: 1000, 
                },
              }
            );
      
            // Extract and store the search results from the response
            setTechProfiles(response.data.hits);
            setLoadingTechProfiles(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingTechProfiles(false)
        }
    }

    useEffect(() => {
        setActiveTechs(techProfiles.filter((profile) => {
            return techTeam.some((member) => member.Member_Contact === profile.objectID && member.Status === "Active")
        }))
    },[techTeam, techProfiles])
   
    useEffect(() => {
        getItcDetails()
        fetchContracts()
        fetchTechTeam()
        fetchTechProfiles()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchTickets()
    }, [contractData])

    useEffect(() => {
        if(itcDetails?.Company_Name){
            getAndSetGroupCoversation()
        }
    }, [itcDetails])

    const statsData =  [
        {
            key: "contracts_assigned",
            title: 'Contracts Assigned',
            value: contractData.length || 0,
        },
        {
            key: "tickets_opened",
            title: 'Tickets Opened',
            value: ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes("open")))?.length || 0,
        },
        {
            key: "tickets_closed",
            title: 'Tickets Closed',
            value: ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes("closed")))?.length || 0,
        },
        {
            key: "total_hours_worked",
            title: 'Total Hours Worked',
            value: ticketData.reduce((acc, item) => {
                if(item?.custom_data?.duration === undefined) return acc
                return  acc + Math.round(Number(item?.custom_data?.duration)/60)
            }, 0) || 0,
        },
        {
            key: "contracts_ended",
            title: 'Contracts Ended',
            value: contractData.filter((ele) => (ele?.Status?.toLowerCase().includes("terminated")))?.length || 0,
        },
        {
            key: "total_technicians",
            title: 'Total Technicians',
            value: activeTechs?.length || 0,
        },
    ]

    const getStatsInfo = (key) => {
        switch (key) {
            case "contracts_assigned":
                return `Total number of contracts assigned to ${itcDetails?.Company_Name}`
            case "tickets_opened":
                return "Total number of tickets opened by you"
            case "tickets_closed":
                return "Total number of tickets closed by you"
            case "total_hours_worked":
                return `Total number of hours you've worked for ${itcDetails?.Company_Name}`
            case "contracts_ended":
                return `Total number of contracts ended in ${itcDetails?.Company_Name}`
            case "total_technicians":
                return `Total number of technicians in ${itcDetails?.Company_Name}`
            default:
                return ""
        }
    }

    if(loadingDetails || loadingContracts || loadingGroupConversation){
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title> ITSC Dashboard | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_PROFILES,  
                        breadcrumbName: 'ITSC Profiles',
                    },
                    { 
                        breadcrumbName: capitalizeFirstLetter(itcDetails?.Company_Name),
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn itc-dashboard-ctn">
                            <div className="itc-dashboard-header">
                                <div className="dashboard-welcome-section">
                                    <div>
                                        <Space align='center' wrap>
                                            <Title level={3} style={{marginBottom:0, color:"#000", fontSize:20}}>Welcome to {capitalizeFirstLetter(itcDetails?.Company_Name)}</Title>
                                            <a href={addSubdomainToBaseUrl(itcDetails?.Subdomain)} target="_blank" rel="noopener noreferrer">  
                                            {
                                                addSubdomainToBaseUrl(itcDetails?.Subdomain).includes('http') &&
                                                addSubdomainToBaseUrl(itcDetails?.Subdomain).replace('https://', 'www.').replace('http://', 'www.')
                                            } 
                                            <ExportOutlined style={{color:SECONDARY_COLOR, fontSize:14, marginLeft:5}}/></a>
                                        </Space>
                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", margin:0}}>Here you can access and track all your company IT activities, metrics & needs.</Paragraph>
                                    </div>
                                    {
                                        itcDetails?.Owner_Contact === tech?.id &&
                                        <Button 
                                            type="primary"
                                            style={{width:122}}
                                            onClick={(e) => {
                                                history.push(ROUTES.ITC_PROFILE.replace(':id', params?.id))  
                                                e.stopPropagation()
                                            }}
                                        >
                                            Edit ITSC
                                        </Button>
                                    }
                                </div>
                                <div className="itc-dashboard-stats">
                                    <Row gutter={[16, 16]}>
                                        {
                                            statsData.map((item) => (
                                                <Col xs={24} sm={12} md={8} lg={4} xl={4} key={item.key}>
                                                    <div className="itc-dashboard-stats-item">
                                                        <div className="itc-dashboard-stats-item-content">
                                                            <Title level={4} style={{marginBottom:8}}>{item.value}</Title>
                                                            <Paragraph style={{margin:0, fontSize:14, color:"rgba(0, 0, 0, 0.65)"}}>{item.title} 
                                                            <Tooltip title={getStatsInfo(item.key)}>
                                                                <InfoCircleOutlined className='stats-info-icon'/> 
                                                            </Tooltip>
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </div>
                            <div className="itc-dashboard-content">
                                <div className="contract-ticket-section">
                                    <ActiveUser
                                        contractData={contractData}
                                        ticketData={ticketData}
                                        loadingContracts={loadingContracts}
                                        client={null}
                                        tech={tech}
                                        isFromDashboard={false}
                                        itcDetails={itcDetails}
                                    />
                                </div>
                                <div className="message-job-section">
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} sm={24} md={24} xl={12}>
                                            {
                                                groupConversations && 
                                                <MessagesRightPaneView
                                                    key={`messages-${groupConversations.id}`}
                                                    conversation={groupConversations}
                                                    user={tech}
                                                    chatBoxStatus={true}
                                                    serviceDetails={true}
                                                    contractPage
                                                    onClickBack={() => ''}
                                                    isTech={true}
                                                    isFromItc={true}
                                                    // isContractPaused={contractDetails.Status === contractStatuses.Paused}
                                                />
                                            }
                                        </Col>
                                        <Col xs={24} sm={24} md={24} xl={12}>
                                            <div className="tech-team-content" style={{height:"100%"}}>
                                                <div className="tech-team-heading">
                                                    <div>
                                                        <Title level={4} style={{marginBottom:12, color:"#000"}}>Team Members</Title>
                                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>List of all your current active team members</Paragraph> 
                                                    </div>
                                                    {  
                                                        itcDetails?.Owner_Contact === tech?.id &&
                                                        <Link to={ROUTES.ITC_TECH_TEAM.replace(':id', params?.id)} className="view-all">Manage Team</Link>
                                                    }
                                                </div>
                                                <div className="tech-team-listings">
                                                   {
                                                        <List
                                                            itemLayout='vertical'
                                                            dataSource={activeTechs}
                                                            renderItem={item => (
                                                                <List.Item key={item.objectID}>
                                                                    <Space size={12}>
                                                                        <Avatar 
                                                                            size={74} 
                                                                            src={item?.image}
                                                                            shape='square'
                                                                            style={{color:SECONDARY_COLOR}}
                                                                        >
                                                                            {item?.name?.split(" ")[0][0]}{item?.name?.split(" ")[1][0].toUpperCase()}
                                                                        </Avatar>
                                                                        <div className="tech-team-tech-details">
                                                                            <Space className='d-flex'>
                                                                                <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{item?.name}</Paragraph>
                                                                                <Badge status={itcDetails?.Owner_Contact === item?.objectID ? "success" : "default"} text={itcDetails?.Owner_Contact === item?.objectID ? "Owner" : `Member`} />
                                                                            </Space>
                                                                            <Space size={3}>
                                                                                <EnvironmentOutlined style={{marginTop:4}}/>
                                                                                <Paragraph  style={{margin:0, color:"#000"}}>{item?.city}{item?.state ? `, ${item?.state}` : ""}</Paragraph>
                                                                            </Space>
                                                                            <Link className="view-link" to={ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${item.objectID}`)}>View Profile</Link>
                                                                        </div>
                                                                    </Space>
                                                                </List.Item>
                                                            )}
                                                        />
                                                   }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                               {
                                    itcDetails?.Owner_Contact === tech?.id &&
                                    <div className="join-request-section">
                                        <div className="join-request-heading">
                                            <Title level={4} style={{marginBottom:12, color:"#000"}}>Join Requests</Title>
                                            { 
                                                loadingTeam ?  <LoadingTechCards/> : 
                                                <JoinRequests 
                                                    itcDetails={itcDetails}
                                                    fetchTechTeam={fetchTechTeam}
                                                    techTeam={techTeam}
                                                />
                                            }
                                        </div>  
                                    </div>         
                                }           
                            </div>     
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default ItcDashboard