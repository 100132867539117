// External imports
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    FacebookOutlined,
    WhatsAppOutlined,
    MailOutlined,
    LinkedinOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {notification, Space} from 'antd';
import {CopyToClipboard} from "react-copy-to-clipboard";

// Internal Imports
import {ReactComponent as TwitterOutlined} from '../../assets/images/twitter-icon.svg'

const handleURLCopyClick = () => {
    notification['success']({
        message: 'TecMe',
        description: 'Link copied.',
    });
}

const ShareTechProfile = () => {
    const url = window.location.href.replace("#", "%23");

    return (
        <Space>
            <FacebookShareButton url={url}>
                <FacebookOutlined className="fs-4" />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
                <TwitterOutlined className="fs-4" />
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
                <WhatsAppOutlined className="fs-4" />
            </WhatsappShareButton>
            <EmailShareButton url={url}>
                <MailOutlined className="fs-4" />
            </EmailShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinOutlined className="fs-4" />
            </LinkedinShareButton>
            <CopyToClipboard onCopy={handleURLCopyClick} text={url}>
                <LinkOutlined className="fs-4" /> 
            </CopyToClipboard>
        </Space>
    )
}

export const ShareTechProfileDesktop = () => {
    const url = window.location.href.replace("#", "%23");

    return (
        <Space direction="vertical" size={0} style={{width:"100%"}}>
             <CopyToClipboard onCopy={handleURLCopyClick} text={url}>
                <Space className="share-social-icon">
                    <LinkOutlined className="fs-6" /> 
                    <p style={{marginBottom:0}}>Copy Link</p> 
                </Space>
            </CopyToClipboard>
            <FacebookShareButton url={url}>
                <Space className="share-social-icon" >
                    <FacebookOutlined className="fs-6" /> 
                    <p style={{marginBottom:0}}>Facebook</p> 
                </Space>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
                <Space className="share-social-icon">
                    <TwitterOutlined style={{width:16}}/>
                    <p style={{marginBottom:0}}>Twitter</p> 
                </Space>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
                <Space className="share-social-icon">
                    <WhatsAppOutlined className="fs-6" />
                    <p style={{marginBottom:0}}>Whatsapp</p> 
                </Space>
            </WhatsappShareButton>
            <EmailShareButton url={url}>
                <Space className="share-social-icon">
                    <MailOutlined className="fs-6" />
                    <p style={{marginBottom:0}}>Mail</p> 
                </Space>
            </EmailShareButton>
            <LinkedinShareButton url={url}>
                <Space className="share-social-icon">
                    <LinkedinOutlined className="fs-6" />
                    <p style={{marginBottom:0}}>Linkedin</p> 
                </Space>
            </LinkedinShareButton>
        </Space>
    )
}

export default ShareTechProfile