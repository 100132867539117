import React, {useEffect, useState} from 'react'
import { Avatar, Button, Rate, Typography, Space, List, Tabs, notification  } from 'antd'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import TechTeam, { LoadingTechCards } from '../../create-itc/components/tech-team'
import { EnvironmentOutlined } from '@ant-design/icons'
import BackIcon from '../../create-itc/assets/images/back-icon.svg'
import { ApiBaseHelper, BASE_URL } from '../../../../../../utils/api-base-helper'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ROUTES from '../../../../../../utils/routes'
import { getAlgoliaIndex } from '../../../../common/public/instant-search/utils/helper'
import axios from 'axios'
import ActionModal from '../../create-itc/components/delete-modal'
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import JoinRequests from './join-requests'

const { Title, Paragraph } = Typography

const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const ProfileTechTeam = ({itcDetails, itcId}) => {
    const tech = useSelector(state => state.tech)
    const [inviteMore, setInviteMore] = useState(false)
    const [techTeam, setTechTeam] = useState([])
    const [techProfiles, setTechProfiles] = useState([])
    const [invitedTechs, setInvitedTechs] = useState([])
    const [acceptedTechs, setAcceptedTechs] = useState([])
    const [loadingTeam, setLoadingTeam] = useState(false)
    const [loadingTechProfiles, setLoadingTechProfiles] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [removeModal, setRemoveModal] = useState(false)
    const [memberItcId, setMemberItcId] = useState(null)
    const [memberTechId, setMemberTechId] = useState(null)
    const [groupConversation, setGroupConversation] = useState(null)
    const [currentTab, setCurrentTab] = useState("active-members")
    const history = useHistory()

    const params = new URLSearchParams(window.location.search)

    const fetchTechTeam = () => {
        setLoadingTeam(true)
        ApiBaseHelper.get({
            url: `itc/member/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setTechTeam(res?.data?.data)
            setLoadingTeam(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTeam(false)
        })       
    }

      // fetch tech profiles
      const fetchTechProfiles = async () => {
        setLoadingTechProfiles(true)
        try {
            const response = await axios.get(
              `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
              {
                headers: {
                  'X-Algolia-API-Key': algoliaSearchKey,
                  'X-Algolia-Application-Id': algoliaAppID,
                },
                params: {
                  hitsPerPage: 1000, 
                },
              }
            );
      
            // Extract and store the search results from the response
            setTechProfiles(response.data.hits);
            setLoadingTechProfiles(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingTechProfiles(false)
        }
    }

     // Function to get and set group conversations for the commpany
     const getAndSetCoversation = async () => {
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        setGroupConversation(groupConversations.find((c) => c.group.toLowerCase() === (itcDetails?.Company_Name?.toLowerCase())))
    }

    // Function to remove member from group chat after accepting invite
    const removeMemberFromGroupConversation = async () => {
        const newParticipants =
          groupConversation.participants?.filter(
            (p) => p !== memberTechId
          );
        await FirestoreHelper.updateGroupConversationMembers({
          participants: newParticipants,
          conversation_id: groupConversation.id,
        });
        try {
          const payload = {
            Title: groupConversation.group,
            Participant: memberTechId,
          }
          await Axios.delete(`${BASE_URL}/conversation/${groupConversation.id}/participant`, {
            headers: {
              Authorization: "Bearer " + tech?.token,
            },
            data: {...payload}
          });
        } catch (error) {
          console.error(error)                
        }
        setRemoving(false)
        notification.success({
            message: 'TecMe',
            description: 'Member removed successfully'
        })
        fetchTechTeam()
        setRemoveModal(false)
    }

    // Remove member
    const handleMemberRemove = () => {
        setRemoving(true)
        ApiBaseHelper.put({
            url: `itc/member/${memberItcId}?type=remove`,	
            headers: {
                Authorization: 'Bearer ' + tech?.token,
            },
            payload: {
                Status: 'Inactive'
            }
        }).then((res) => {
            if(groupConversation){
               removeMemberFromGroupConversation()
            }else{
                setRemoving(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Member removed successfully'
                })
                setRemoveModal(false)
                fetchTechTeam()
            }   
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while removing member'
            })
            setRemoving(false)
            console.log(err)
        })
    }


    useEffect(() => {  
        fetchTechProfiles()
    }, [])

    useEffect(() => {
        fetchTechTeam()
    }, [techProfiles])

    useEffect(() => {
        if(itcDetails?.Company_Name){
            getAndSetCoversation()
        }
    }, [itcDetails])

    useEffect(() => {
        setInvitedTechs(techProfiles.filter((profile) => {
            return techTeam.some((member) => member.Member_Contact === profile.objectID && member.Status === "Invited")
        }))
        setAcceptedTechs(techProfiles.filter((profile) => {
            return techTeam.some((member) => member.Member_Contact === profile.objectID && member.Status === "Active")
        }))
    },[techTeam, techProfiles])

    useEffect(() => {
        if(params.get('tab') === "requests"){
            setCurrentTab("join-requests")
        }
    }, [params.get('tab')])

    useEffect(() => {
        if(params.get('tab') === "invite-more"){
            setInviteMore(true)
        }
    }, [params.get('tab')])

    return (
        <div className="profile-tech-team-section">
        {
            inviteMore ? 
            <>
                <Space 
                    size={10} 
                    onClick={() => {
                        setInviteMore(false)
                        history.push(`${ROUTES.ITC_TECH_TEAM.replace(':id', itcId)}`) 
                    }}
                    className='itc-back-btn'
                >
                    <img src={BackIcon} alt="Back Icon" />
                    <span>Go Back</span>
                </Space> 
                <TechTeam
                    isFromItcProfile={true}
                    setTechTeam={setTechTeam}
                    techTeam={techTeam}
                    fetchTechTeam={fetchTechTeam}
                /> 
            </>
           :
            <> 
                <Space size={15}>
                    <Title level={4} style={{fontSize:16, marginBottom:0}} className="itc-form-title">Your Team</Title>
                    <Button type='secondary' 
                        onClick={() => {
                            setInviteMore(true)
                            history.push(`${ROUTES.ITC_TECH_TEAM.replace(':id', itcId)}?tab=invite-more`)  
                        }}
                    >+ Invite More</Button>
                </Space>
                <div className="itc-tech-team">
                {
                    (loadingTeam || loadingTechProfiles) ? 
                    <LoadingTechCards/> : 
                    <Tabs
                        defaultActiveKey={currentTab}
                        onChange={(key) => setCurrentTab(key)}
                    >
                        <Tabs.TabPane tab="Active Members" key="active-members">
                            <CustomRenderEmpty
                                description={"No team members found."} 
                            > 
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 2,
                                        lg: 2,
                                        xl: 3,
                                        xxl: 3
                                    }}
                                    // className="tech-team-list-ctn"
                                    dataSource={acceptedTechs}
                                    pagination={{ pageSize: 10 }}
                                    renderItem={item => (
                                        <List.Item>
                                            <div className="tech-team-item" key={item?.objectID}>
                                                <div className="tech-team-tech-info">
                                                    <Space size={12}>
                                                        <Avatar 
                                                            size={74} 
                                                            src={item?.image}
                                                            shape='square'
                                                            style={{color:SECONDARY_COLOR}}
                                                        >
                                                            {item?.name?.split(" ")[0][0]}{item?.name?.split(" ")[1][0].toUpperCase()}
                                                        </Avatar>
                                                        <div className="tech-team-tech-details">
                                                            <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{item?.name}</Paragraph>
                                                           { 
                                                                (item?.city || item.state) &&
                                                                <Space size={3}>
                                                                    <EnvironmentOutlined style={{marginTop:4}}/>
                                                                    <Paragraph  style={{margin:0, color:"#000"}}>{item?.city}{item?.state ? `, ${item?.state}` : ""}</Paragraph>
                                                                </Space>
                                                            }
                                                        { 
                                                            item?.rating ?
                                                            <div className="rating-label">  
                                                                <Paragraph className="rating-text">{Math.round(item?.rating)}</Paragraph>
                                                                <Rate disabled defaultValue={Math.round(item?.rating)} count={1} style={{color:SECONDARY_COLOR, fontSize:14, marginTop:4}} />
                                                                {/* <Paragraph className="reviews-text">({item?.data?.Total_Reviews})</Paragraph> */}
                                                            </div> :
                                                            <div className="no-rating-label"></div>
                                                            }
                                                        </div>
                                                    </Space>
                                                </div>
                                                <div className="tech-team-item-summary">
                                                    <Paragraph ellipsis={{rows:2}} style={{fontWeight:300, color:"#000", margin:0, fontSize:14}}>{item?.bio}</Paragraph>
                                                    <div className='summary-btn-ctn'>
                                                        <Button 
                                                            type='secondary' 
                                                            className='summary-btn'
                                                            onClick={() => {window.open(
                                                                `${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${item.objectID}`)}`, '_blank'
                                                            )
                                                            }}
                                                        >
                                                            View Profile
                                                        </Button>
                                                        <Button 
                                                            type='primary' 
                                                            className='summary-btn' 
                                                            onClick={() => {
                                                                setMemberItcId(techTeam?.find((member) => member.Member_Contact === item.objectID)?.id)
                                                                setMemberTechId(item.objectID)
                                                                setRemoveModal(true)
                                                            }}
                                                            disabled={tech?.id === item.objectID}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </CustomRenderEmpty>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Invited Members" key="invited-members">
                            <CustomRenderEmpty
                                description={"No team members found."} 
                            > 
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 2,
                                        lg: 2,
                                        xl: 3,
                                        xxl: 3
                                    }}
                                    // className="tech-team-list-ctn"
                                    dataSource={invitedTechs}
                                    pagination={{ pageSize: 10 }}
                                    renderItem={item => (
                                        <List.Item>
                                            <div className="tech-team-item" key={item?.objectID}>
                                                <div className="tech-team-tech-info">
                                                    <Space size={12}>
                                                        <Avatar 
                                                            size={74} 
                                                            src={item?.image}
                                                            shape='square'
                                                            style={{color:SECONDARY_COLOR}}
                                                        >
                                                            {item?.name?.split(" ")[0][0]}{item?.name?.split(" ")[1][0].toUpperCase()}
                                                        </Avatar>
                                                        <div className="tech-team-tech-details">
                                                            <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{item?.name}</Paragraph>
                                                            { 
                                                                (item?.city || item.state) &&
                                                                <Space size={3}>
                                                                    <EnvironmentOutlined style={{marginTop:4}}/>
                                                                    <Paragraph  style={{margin:0, color:"#000"}}>{item?.city}{item?.state ? `, ${item?.state}` : ""}</Paragraph>
                                                                </Space>
                                                            }
                                                            { 
                                                            item?.rating ?
                                                            <div className="rating-label">  
                                                                <Paragraph className="rating-text">{Math.round(item?.rating)}</Paragraph>
                                                                <Rate disabled defaultValue={Math.round(item?.rating)} count={1} style={{color:SECONDARY_COLOR, fontSize:14, marginTop:4}} />
                                                                {/* <Paragraph className="reviews-text">({item?.data?.Total_Reviews})</Paragraph> */}
                                                            </div> :
                                                            <div className="no-rating-label"></div>
                                                            }
                                                        </div>
                                                    </Space>
                                                </div>
                                                <div className="tech-team-item-summary">
                                                    <Paragraph ellipsis={{rows:2}} style={{fontWeight:300, color:"#000", margin:0, fontSize:14}}>{item?.bio}</Paragraph>
                                                    <div className='summary-btn-ctn'>
                                                        <Button 
                                                            type='secondary' 
                                                            className='summary-btn'
                                                            onClick={() => {window.open(
                                                                `${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${item.objectID}`)}`, '_blank'
                                                            )
                                                            }}
                                                        >
                                                            View Profile
                                                        </Button>
                                                        <Button 
                                                            type='secondary' 
                                                            className='summary-btn' 
                                                            disabled={true}
                                                            // onClick={() => removeMemberFromTeam(item)}
                                                        >
                                                            + Invite
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </CustomRenderEmpty>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Join Requests" key="join-requests">
                           <JoinRequests
                                itcDetails={itcDetails}
                                fetchTechTeam={fetchTechTeam}
                                techTeam={techTeam}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                   
                }
                </div>
            </>
        }
        <ActionModal
            visible={removeModal}
            handleCancel={() => setRemoveModal(false)}
            handleClick={() => handleMemberRemove()}
            message={"This action will remove the technician from your team. Please make sure you want to remove this technician before proceeding."}
            loading={removing}
            deleteText={"Remove"}
        />
        </div>
    )
}

export default ProfileTechTeam