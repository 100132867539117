import React, { useEffect, useState } from 'react'
import CompanyLogo from '../assets/images/company-logo.png'
import { FacebookOutlined, GlobalOutlined, InstagramOutlined, LinkedinOutlined, MobileOutlined, TikTokOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons'
import { PrimaryButton } from './button'
import '../assets/css/styles.scss'
import { Typography } from 'antd'
import { capitalizeFirstLetter } from '../../../../../../utils/helper'

const { Paragraph } = Typography

let MENU_LIST = [
    {
        name: 'Home',
        section: '#home'
    },
    {
        name: 'About us',
        section: '#about-us'
    },
    // {
    //     name: 'Specializations',
    //     section: `#specializations`
    // },
    // {
    //     name: 'Team',
    //     section: `#team`
    // },
    // {
    //     name: 'Testimonials',
    //     section: `#testimonials`
    // },
    // {
    //     name: 'FAQs',
    //     section: `#faqs`
    // }
]

export function ensureHttps(url) {
    // Check if the URL already starts with https://
    if (!url.startsWith('https://')) {
        // If the URL starts with www., replace it with https://
        if (url.startsWith('www.')) {
            url = 'https://' + url.substring(4);
        } else {
            // If the URL doesn't start with https:// or www., prepend https://
            url = 'https://' + url;
        }
    }
    return url;
}


const ItcNav = ({itcContent, brandColor}) => {

    const [activeNav, setActiveNav] = useState('#home')
    const [openMenu, setOpenMenu] = useState(false);

    // check for current section based on url
    const currentSection = window.location.hash

    useEffect(() => {
        setActiveNav(currentSection)
    }, [currentSection])

    // check for current section based on scroll
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const sections = document.querySelectorAll('section')

            sections.forEach(section => {
                const sectionTop = section.offsetTop
                const sectionHeight = section.clientHeight
                if (currentScrollPos >= sectionTop - sectionHeight / 3) {
                    setActiveNav(`#${section.id}`)
                }
            })
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // useEffect(() => {
    //     if(itcContent?.Testimonials?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Testimonials')
    //     }
    //     if(itcContent?.FAQ?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'FAQs')
    //     }
    //     if(itcContent?.Members?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Team')
    //     }
    //     if(itcContent?.Categories?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Specializations')
    //     }
    // }, [itcContent])

    return (
        <nav className="itc-nav-section">
            <div className="itc-nav-ctn">
                <div className="itc-nav-logo-ctn">
                    { 
                        itcContent?.Brand ?
                        <div className="itc-logo">
                            <img src={itcContent?.Brand} alt="company logo" />
                        </div> :
                        <Paragraph style={{color:"#000", fontSize:21, fontWeight:500, marginBottom:0}}>{capitalizeFirstLetter(itcContent?.Company_Name)}</Paragraph>
                    }
                </div>
                <div className="itc-menu-list-ctn">
                    <ul className='menu-list'>
                         {
                            MENU_LIST.map((menu, index) => {
                                return (
                                    <li 
                                        className="menu-item" 
                                        key={index}
                                        style={{
                                            borderBottom: activeNav === menu.section && `1.5px solid ${brandColor}`
                                        }}
                                    >
                                        <a href={menu.section}
                                            style={{
                                                color: activeNav === menu.section ? brandColor : '#000'
                                            }}
                                        >{menu.name}</a>
                                    </li>
                                )
                            })
                        }
                        {
                            itcContent?.Categories?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#specializations' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#specializations" style={{color: activeNav === '#specializations' ? brandColor : '#000'}}>Specializations</a>
                            </li>
                        }
                        {
                            itcContent?.Members?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#team' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#team" style={{color: activeNav === '#team' ? brandColor : '#000'}}>Team</a>
                            </li>
                        }
                        {
                            itcContent?.Testimonials?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#testimonials' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#testimonials" style={{color: activeNav === '#testimonials' ? brandColor : '#000'}}>Testimonials</a>
                            </li>
                        }
                        {
                            itcContent?.FAQ?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#faqs' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#faqs" style={{color: activeNav === '#faqs' ? brandColor : '#000'}}>FAQs</a>
                            </li>
                        }
                    </ul>
                    <div className="itc-nav-cta-ctn">
                        {
                            itcContent?.custom_data?.facebookUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.facebookUrl)} target="_blank" rel="noopener noreferrer">
                                <FacebookOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                        }
                        {
                            itcContent?.custom_data?.twitterUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.twitterUrl)} target="_blank" rel="noopener noreferrer">
                                <TwitterOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                        }
                       {
                            itcContent?.custom_data?.linkedInUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.linkedInUrl)} target="_blank" rel="noopener noreferrer">
                                <LinkedinOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.youtubeUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.youtubeUrl)} target="_blank" rel="noopener noreferrer">
                                <YoutubeOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.tiktokUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.tiktokUrl)} target="_blank" rel="noopener noreferrer">
                                <TikTokOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.instagramUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.instagramUrl)} target="_blank" rel="noopener noreferrer">
                                <InstagramOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.websiteUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.websiteUrl)} target="_blank" rel="noopener noreferrer">
                                <GlobalOutlined style={{color:brandColor, fontSize:20}}/>
                            </a>
                       }
                    </div>
                </div>
                <div 
                    className={`itc-hamburger-menu ${openMenu && 'itc-hamburger-menu-toggle'}`}
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={`${openMenu ? 'itc-mobile-navigation-menu' : 'itc-mobile-navigation-menu-closed'}`}>
                <div className={'itc-mobile-navigation-menu-ctn'}>
                    <ul>
                        {
                            MENU_LIST.map((menu, index) => {
                                return(
                                    <li 
                                        className="menu-item" 
                                        key={index}
                                        style={{
                                            borderBottom: activeNav === menu.section && `1.5px solid ${brandColor}`
                                        }}
                                        onClick={() => setOpenMenu(false)}
                                    >
                                        <a href={menu.section}
                                            style={{
                                                color: activeNav === menu.section ? brandColor : '#000'
                                            }}
                                        >{menu.name}</a>
                                    </li>
                                )
                            })
                        }
                        {
                            itcContent?.Categories?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#specializations' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#specializations" style={{color: activeNav === '#specializations' ? brandColor : '#000'}}>Specializations</a>
                            </li>
                        }
                        {
                            itcContent?.Members?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#team' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#team" style={{color: activeNav === '#team' ? brandColor : '#000'}}>Team</a>
                            </li>
                        }
                        {
                            itcContent?.Testimonials?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#testimonials' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#testimonials" style={{color: activeNav === '#testimonials' ? brandColor : '#000'}}>Testimonials</a>
                            </li>
                        }
                        {
                            itcContent?.FAQ?.length > 0 &&
                            <li 
                                className="menu-item" 
                                style={{
                                    borderBottom: activeNav === '#faqs' && `1.5px solid ${brandColor}`
                                }}
                            >
                                <a href="#faqs" style={{color: activeNav === '#faqs' ? brandColor : '#000'}}>FAQs</a>
                            </li>
                        }
                    </ul>
                    <div className="mobile-itc-nav-cta-ctn">
                        {
                            itcContent?.custom_data?.facebookUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.facebookUrl)} target="_blank" rel="noopener noreferrer">
                                <FacebookOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                        }
                        {
                            itcContent?.custom_data?.twitterUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.twitterUrl)} target="_blank" rel="noopener noreferrer">
                                <TwitterOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                        }
                       {
                            itcContent?.custom_data?.linkedInUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.linkedInUrl)} target="_blank" rel="noopener noreferrer">
                                <LinkedinOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.youtubeUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.youtubeUrl)} target="_blank" rel="noopener noreferrer">
                                <YoutubeOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.tiktokUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.tiktokUrl)} target="_blank" rel="noopener noreferrer">
                                <TikTokOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.instagramUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.instagramUrl)} target="_blank" rel="noopener noreferrer">
                                <InstagramOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                       }
                       {
                            itcContent?.custom_data?.websiteUrl &&
                            <a href={ensureHttps(itcContent?.custom_data?.websiteUrl)} target="_blank" rel="noopener noreferrer">
                                <GlobalOutlined style={{color:brandColor, fontSize:40}}/>
                            </a>
                       }
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default ItcNav