import { useState } from 'react'
import { MoreOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Form, List, Modal, Popover, Radio, Row, Space, Table, Tabs, Tooltip, Typography, message } from 'antd'
import EmptyMailImage from '../assets/images/empty-mail.png'
import React from 'react'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { canReadPageOnly, capitalizeFirstLetter, getTableTimeFormat } from '../../../../../utils/helper'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import moment from 'moment'
import ConfirmPopupModal from './confirm-popup-modal'

const teamRoles = [
    {
        name:"Administrator",
        points:["Unrestricted access to all features and functionalities of the platform", "Manage organization team members"]
    },
    {
        name:"Manager",
        points:["All recruiter permissions", "Hire technicians and purchase IT services", "Manage payment methods"]
    },
    {
        name:"Recruiter",
        points:["Create and engage in conversations with technicians", "Manage saved lists of technicians for future reference", "Search for and explore the technician profiles based on various criteria"]
    }
]

const { Title, Paragraph, Text } = Typography
const { useForm } = Form

const TeamMembersTable = ({teamMembersList, user, getTeamMembersList, loadingList}) => {
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openRemoveModal, setOpenRemoveModal] = useState(false)
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [openResendModal, setOpenResendModal] = useState(false)
    const [activeItemsToShow, setActiveItemsToShow] = useState(5)
    const [invitedItemsToShow, setInvitedItemsToShow] = useState(5)
    const [currentTab, setCurrentTab] = useState('');
    const [currentMember, setCurrentMember] = useState(null)
    const {windowWidth} = useWindowSize();
    const [memberId, setMemberId] = useState(null)
    const [actionLoading, setActionLoading] = useState(false)
    const [form] = useForm()

    let activeMembersList = teamMembersList.filter((member) => member?.Status === "Active")
    let invitedMembersList = teamMembersList.filter((member) => member?.Status === "Invited").sort((a, b) => moment(b?.CreatedDate).local().diff(moment(a?.CreatedDate).local()))

    const teamOwner = activeMembersList.find((member) => member?.Role_Title === "Administrator" && member?.Invited_By === user?.id)

    const teamAdmin = activeMembersList.find((member) => member?.Role_Title === "Administrator" && member?.Contact?.Email_Address === user?.email)

    const roleTooltipInfo = (
        <div className='role-tooltip-ctn'>
            {
                teamRoles.map((role, index) => {
                    return(
                        <div className='role-item' key={index}>
                            <p style={{fontWeight:500, textTransform:"capitalize", margin:0}}>{role.name}</p> 
                            <ul>
                                {
                                    role.points.map((point) => {
                                        return(
                                            <li key={point}>{point}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                })
            }
        </div>
    )

    const removeMember = (record) => {
        setActionLoading(true)
        ApiBaseHelper.delete({
            url: `user-management/remove-user/${record.id}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        }).then((res) => {
            message.success({
                content:"Member removed successfully", 
                className: 'custom-success-message-alert'
            })
            getTeamMembersList()
            setActionLoading(false)
            setOpenRemoveModal(false)
        }).catch((error) => {
            console.log(error)
            message.error({
                content: "Something went wrong",
                className: 'custom-error-message-alert'
            })
            setActionLoading(false)
        })
    }

    const withdrawInvite = (record) => {
        setActionLoading(true)
        ApiBaseHelper.delete({
            url: `user-management/withdraw-invite/${record.id}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
            payload:{
                Invited_Email: record?.Invited_Email,
                Role_Title: record?.Role_Title,
                Organization_Name: record?.Name,
                OCA_Id: record?.id,
            }
        }).then((res) => {
            message.success({
                content:"Invite withdrawn successfully", 
                className: 'custom-success-message-alert'
            })
            getTeamMembersList()
            setActionLoading(false)
            setOpenWithdrawModal(false)
        }).catch((error) => {
            console.log(error)
            message.error({
                content: "Something went wrong",
                className: 'custom-error-message-alert'
            })
            setActionLoading(false)
        })
    }

    const resendInvite = (record) => {
        setActionLoading(true)
        ApiBaseHelper.post({
            url: `user-management/resend-invite`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
            payload:{
                Invited_Email: record?.Invited_Email,
                Role_Title: record?.Role_Title,
                Organization_Name: user?.organization?.Name,
                OCA_Id: record?.id,
            }
        }).then((res) => {
            message.success({
                content:"Invite resent successfully", 
                className: 'custom-success-message-alert'
            })
            getTeamMembersList()
            setActionLoading(false)
            setOpenResendModal(false)
        }).catch((error) => {
            console.log(error)
            message.error({
                content: "Something went wrong",
                className: 'custom-error-message-alert'
            })
            setActionLoading(false)
        })
    }

    const editMemberRole = (value) => {
        setActionLoading(true)
        ApiBaseHelper.put({
            url: `user-management/role/${memberId}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
            payload:{
                Role:value?.Role,
            }
        }).then((res) => {
            message.success({
                content:"Role updated successfully", 
                className: 'custom-success-message-alert'
            })
            setOpenEditModal(false)
            getTeamMembersList()
            setActionLoading(false)
        }).catch((error) => {
            console.log(error)
            setActionLoading(false)
            message.error({
                content: "Something went wrong",
                className: 'custom-error-message-alert'
            })
        })
    }


    const activeMembersColumns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (_, record) => {
            return(
                <Space>
                     <Avatar src={record?.Contact?.imgUrl} size={'small'} icon={<UserOutlined />}>
                        {record?.Contact?.Full_Name && record?.Contact?.Full_Name[0]?.toUpperCase()}
                    </Avatar> 
                    <Text>{record?.Contact?.Full_Name}</Text>
                </Space>
            )
          },
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          render:(_, record) => {
            return(<Text>{record?.Contact?.Email_Address}</Text>)
          }
        },
        {
          title: <Space>
            <Text>Role</Text>
            <Tooltip placement='bottom' overlayStyle={{left:"840px"}} arrowPointAtCenter overlayClassName='role-overlay' title={roleTooltipInfo}>
                <QuestionCircleOutlined style={{cursor:"pointer"}}/>
            </Tooltip>
          </Space>,
          dataIndex: 'role',
          key: 'role',
          render:(_, record) => {
            return(<Text>{capitalizeFirstLetter(record?.Role_Title)}</Text>)
          }
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => 
            (
                <Space size={31} wrap>
                    {
                       ((user?.email !== record?.Contact?.Email_Address) && (teamAdmin?.Invited_By !== record?.Contact?.id)) &&
                        <Paragraph style={{margin:0, color:"#F04A22"}} onClick={() => {
                        setOpenEditModal(true)
                        setMemberId(record?.id)
                        form.setFieldsValue({
                            Role: capitalizeFirstLetter(record?.Role_Title) 
                        })
                        }}>Edit</Paragraph>
                    }
                    {
                       ((user?.email !== record?.Contact?.Email_Address) && (teamAdmin?.Invited_By !== record?.Contact?.id)) &&
                        <Paragraph style={{margin:0, color:"#F04A22"}} onClick={() => {
                            setOpenRemoveModal(true)
                            setCurrentMember(record)
                        }}>Remove</Paragraph>
                    }
                   
                </Space>
            )
        },
    ];

    const invitedMembersColumns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render:(_, record) => {
            return(<Text>{record?.Invited_Email}</Text>)
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            key: 'date',
            render:(_, record) => {
            return(<Text>{getTableTimeFormat(record?.CreatedDate)}</Text>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
            <Space size={31} wrap>
                <Paragraph style={{margin:0, color:"#F04A22"}} onClick={() =>{ 
                    setOpenResendModal(true)
                    setCurrentMember(record)
                }}>Resend</Paragraph>
                <Paragraph style={{margin:0, color:"#F04A22"}} onClick={() => { 
                    setOpenWithdrawModal(true)
                    setCurrentMember(record)
                }}>Withdraw</Paragraph>
            </Space> 
            ),
        },
    ];

    
    return (
        <>
            <Row gutter={80}>
                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                    <Space>
                        <Title level={5} style={{color:"#101828", marginBottom:0}}>Team members</Title>  
                        { windowWidth < 991 && <Tooltip placement="bottom"  title={roleTooltipInfo} overlayClassName='mobile-role-overlay'>
                            <QuestionCircleOutlined style={{cursor:"pointer"}}/>
                        </Tooltip>}
                    </Space>
                    <Paragraph style={{color:"#475467"}}>Manage your existing team and change roles/permissions</Paragraph> 
                </Col>
                <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                    <div className={`table-tab-section ${(invitedMembersList.length === 0 && currentTab === "invitations") && 'no-invited-member-section'}`}>
                        <Tabs defaultActiveKey="active_members" onTabClick={(key) => setCurrentTab(key)}>
                            <Tabs.TabPane tab="Active members" key="active_members">
                                {
                                    windowWidth > 991 ?  
                                    <Table 
                                        columns={activeMembersColumns.filter((ele) => canReadPageOnly(user?.role) ? ele.key !== 'action' : ele )} 
                                        dataSource={activeMembersList} 
                                        loading={loadingList}
                                        /> : 
                                    <>
                                        <List
                                        loading={loadingList}
                                        dataSource={activeMembersList.slice(0, activeItemsToShow)}
                                        renderItem={(item) => (
                                        <List.Item>
                                            <div className='members-list'>
                                                <Space size={12}>
                                                    <Avatar src={item?.Contact?.imgUrl} size={40}> {item?.Contact?.Full_Name && item?.Contact?.Full_Name[0]?.toUpperCase()}</Avatar> 
                                                    <div>
                                                        <Paragraph style={{margin: 0, fontSize:14}}>{item?.Contact?.Full_Name}</Paragraph>
                                                        <Paragraph style={{margin: 0, fontSize:14}}>{item?.Contact?.Email_Address}</Paragraph>
                                                        <Paragraph style={{margin: 0, fontSize:14, textDecoration:"capitalize"}}>{item?.Role_Title}</Paragraph>
                                                    </div>
                                                </Space>
                                               { 
                                                    (!canReadPageOnly(user?.role) &&  ((user?.email !== item?.Contact?.Email_Address) && (teamAdmin?.Invited_By !== item?.Contact?.id))) &&
                                                    <Popover 
                                                    overlayClassName='member-popover'
                                                    placement="bottomRight"
                                                    content={
                                                        <div className='member-popover-content'>
                                                            <div className="member-popover-cta"
                                                            onClick={() => {
                                                                setOpenEditModal(true)
                                                                setMemberId(item?.id)
                                                                form.setFieldsValue({
                                                                    Role: capitalizeFirstLetter(item?.Role_Title)
                                                                })
                                                                }}>Edit</div>
                                                            <div 
                                                            onClick={() => {
                                                                setOpenRemoveModal(true)
                                                                setCurrentMember(item)
                                                            }}className="member-popover-cta">Remove</div>
                                                        </div>
                                                    } 
                                                    trigger="click">
                                                        <MoreOutlined style={{color:" rgba(0, 0, 0, 0.85)", fontSize:20}}/>
                                                    </Popover>
                                                }
                                            </div>
                                        </List.Item>
                                        )}
                                        />
                                        {
                                            activeMembersList.length > activeItemsToShow &&
                                            <Button className='members-load-more-btn' type='secondary' onClick={() => setActiveItemsToShow(prevItem => prevItem + 5)}>Load More</Button>
                                        }
                                    </>
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Invitations" key="invitations">
                            {
                                    invitedMembersList.length === 0 ? 
                                    <div className="no-invites-ctn">
                                        <img src={EmptyMailImage} alt="No Invites" />
                                        <Title level={5} className='empty-invitation-title'>You have no invitations yet</Title>
                                        <Text className='empty-invitation-info'>Get started by inviting someone to join your team</Text>
                                    </div> :   
                                    windowWidth > 991 ?  
                                    <Table 
                                        columns={invitedMembersColumns.filter((ele) => canReadPageOnly(user?.role) ? ele.key !== 'action' : ele )} 
                                        dataSource={invitedMembersList} 
                                        loadingList={loadingList}
                                        /> :
                                    <>
                                        <List
                                        loading={loadingList}
                                        dataSource={invitedMembersList.slice(0, invitedItemsToShow)}
                                        renderItem={(item) => (
                                        <List.Item>
                                        <div className='members-list'>
                                            <div>
                                                <Paragraph style={{margin: 0, fontSize:14}}>{item?.Invited_Email}</Paragraph>
                                            </div>
                                            {
                                                !canReadPageOnly(user?.role) &&
                                                <Popover 
                                                    overlayClassName='member-popover'
                                                    placement="bottomRight"
                                                    content={
                                                        <div className='member-popover-content'>
                                                            <div className="member-popover-cta"onClick={() =>{ 
                                                                setOpenResendModal(true)
                                                                setCurrentMember(item)
                                                            }}>Resend</div>
                                                            <div className="member-popover-cta" onClick={() => { 
                                                                setOpenWithdrawModal(true)
                                                                setCurrentMember(item)
                                                            }}>Withdraw</div>
                                                        </div>
                                                    } 
                                                    trigger="click">
                                                        <MoreOutlined style={{color:" rgba(0, 0, 0, 0.85)", fontSize:20}}/>
                                                </Popover>
                                            }     
                                        </div>
                                        </List.Item>
                                        )}
                                        />
                                        {
                                            invitedMembersList.length > invitedItemsToShow &&
                                            <Button className='members-load-more-btn' type='secondary' onClick={() => setInvitedItemsToShow((prevItem) => prevItem + 5)}>Load More</Button>
                                        }
                                    </>    
                                }
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row> 
             {/* Edit member modal */}
             <Modal
                    title="Edit role for team member" 
                    open={openEditModal} 
                    width={572}
                    okText="Save"
                    okButtonProps={{loading: actionLoading, htmlType:"submit", form:'edit-member-form'}}
                    cancelButtonProps={{type:'secondary'}}
                    // onOk={() => setOpenEditModal(false)} 
                    onCancel={() => setOpenEditModal(false)}
                    className='edit-member-modal'
                    >
                        <Form 
                            form={form}
                            name='edit-member-form'
                            onFinish={editMemberRole}
                            className='edit-member-modal'>
                            <Form.Item name="Role">
                                <Radio.Group>
                                    {
                                        teamRoles.map((role) => (
                                            <div key={role.name}>
                                                <Radio value={role.name}>
                                                    <span style={{fontWeight:500, textTransform:"capitalize"}}>{role.name}</span>
                                                    <ul>
                                                        {
                                                            role.points.map((point) => (
                                                                <li key={point}>{point}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </Radio>
                                            </div>
                                        ))  
                                    }
                                </Radio.Group>
                            </Form.Item>                               
                        </Form>
                </Modal>
                {/* Remove Member Modal */}
                <ConfirmPopupModal
                    open={openRemoveModal}
                    setOpen={setOpenRemoveModal}
                    title={`Are you sure you want to remove ${currentMember?.Contact?.Full_Name}?`}
                    sub_title="By deleting this team member, you will revoke their access to the team"
                    onOk={() => removeMember(currentMember)}
                    okButtonProps={{loading: actionLoading}}
                />
                {/* Resend Invite Modal */}
                <ConfirmPopupModal
                    open={openResendModal}
                    setOpen={setOpenResendModal}
                    title="Are you sure you want to resend the invitation?"
                    sub_title="This action will send a new invitation email to the recipient with the same details."
                    onOk={() => resendInvite(currentMember)}
                    okButtonProps={{loading: actionLoading}}
                />
                {/* Withdraw Invite Modal */}
                <ConfirmPopupModal
                    open={openWithdrawModal}
                    setOpen={setOpenWithdrawModal}
                    title="Are you sure you want to withdraw the invitation?"
                    sub_title="This action will revoke the previously sent invitation"
                    onOk={() => withdrawInvite(currentMember)}
                    okButtonProps={{loading: actionLoading}}
                />
        </>
    )
}

export default TeamMembersTable