import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../utils/constants'
import {Col, Layout, Row, Select, Space, Tooltip, Typography} from 'antd'
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs'
import {  QuestionCircleOutlined,} from '@ant-design/icons'
import './assets/css/styles.scss'
import ROUTES from '../../../../utils/routes'
import { useState } from 'react'
import { ApiBaseHelper } from '../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import TeamMembersTable from './components/team-members-table'
import { canReadPageOnly } from '../../../../utils/helper'
import InviteMembersForm from './components/invite-members-form'

const {Content} = Layout
const {Title, Paragraph, Text} = Typography

const TeamManagement = () => {
    const user = useSelector((state) => state.user)
    const [loadingList, setLoadingList] = useState(false)
    const [teamMembersList, setTeamMembersList] = useState([])

    
    const getTeamMembersList = () => {
        setLoadingList(true)
        ApiBaseHelper.get({
            url: 'user-management/users',
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            setTeamMembersList(res?.data?.data?.data)
            setLoadingList(false)
        })
    }

    useEffect(() => {
        getTeamMembersList()
    }, [])

    return (
        <>
            <MetaTags>
                <title>Manage your team | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <div>
                        <PageHeaderWrapper
                            className=""
                            routes={[
                                {
                                    breadcrumbName: 'Team Management',
                                },
                            ]}
                        />
                        <div className='auth-container'>
                            <div className='auth-page-ctn team-management-ctn'>
                                <div className="team-heading-section">
                                    <Title level={2} style={{marginBottom:8, color:"#101828"}}>Team Management</Title>
                                    <Paragraph style={{color:"#475467"}}>Manage your team members and their account permissions here.</Paragraph>
                                </div>
                                { !canReadPageOnly(user?.role) &&
                                    <div className="invite-team-section">
                                        <Row gutter={80}>
                                            <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                                <Space>
                                                    <Title level={5} style={{color:"#101828", marginBottom:0}}>Invite team members</Title>
                                                    <Tooltip overlayClassName='invite-overlay' placement="bottom" title="With this feature, you can invite new users to join your team and assign them specific roles. By assigning roles, you can control their permissions and ensure smooth project management.">
                                                        <QuestionCircleOutlined style={{cursor:"pointer"}}/>
                                                    </Tooltip>
                                                </Space>   
                                                <Paragraph style={{color:"#475467", marginTop:4}}>Get your projects up and running faster by inviting your team to collaborate.</Paragraph> 
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                                                <InviteMembersForm 
                                                    getTeamMembersList={getTeamMembersList}
                                                    teamMembersList={teamMembersList}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                <div className="team-members-table-section">
                                    <TeamMembersTable 
                                        teamMembersList={teamMembersList} 
                                        user={user}
                                        getTeamMembersList={getTeamMembersList}
                                        loadingList={loadingList}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Content>
        </>
    )
}

export default TeamManagement