import { Button, Col, Progress, Row, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import FindIcon from '../assets/icons/find-icon.svg'
import InviteIcon from '../assets/icons/invite-icon.svg'
import ServiceIcon from '../assets/icons/services-icon.svg'
import HistoryIcon from '../assets/icons/history-icon.svg'
import AddProjectIcon from '../assets/icons/add-project.svg'
import SearchIcon from '../assets/icons/search-icon.svg'
import ROUTES from '../../../../../../utils/routes'
import { CheckOutlined } from '@ant-design/icons'
import IncompleteIcon from '../assets/icons/incomplete-icon-new.svg'
import CompleteIcon from '../assets/icons/complete-icon.svg'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useHistory } from 'react-router-dom'
import { sendVerificationEmail } from '../../../../../../utils/helper'


const {Title, Paragraph} = Typography



const NewUser = ({contractData, ticketData, loadingContracts, client, tech}) => {

    const authUser = client || tech

    const [paymentMethodExist, setPaymentMethodExist] = useState(false) 
    const [companyDetailsExist, setCompanyDetailsExist] = useState(false)
    const [ setUpSteps, setSetUpSteps ] = useState([])
    const [ guideSteps, setGuideSteps ] = useState([])
    const [ loadingPayments, setLoadingPayments ] = useState(false)

    const history = useHistory()

    useEffect(() => {
        setLoadingPayments(true)
        ApiBaseHelper.get({
            url: 'stripe/payment-method',
            headers: {
                Authorization: 'Bearer ' + client?.token
            }
        }).then((res) => { 
            setLoadingPayments(false)
            if(res?.data?.data?.length !== 0){
                setPaymentMethodExist(true)
            }
        }).catch((err) => {
            console.log(err)
            setLoadingPayments(false)
        })
    }, [])

    useEffect(() => {
        if(client?.organization?.Name && client?.organization?.Description && client?.organization?.Website){
            setCompanyDetailsExist(true)
        }
    }, [client])

    
    const CLIENT_SET_UP_STEPS = [
        {
            title:"You verified your email",
            click:() => sendVerificationEmail({
                email: authUser.email,
                userId: authUser.id,
                token: authUser.token
            }),
            description:"It is essential to establish trust, and ensure secure communication with IT professionals ",
            isComplete:client?.email_verification_banner
        },
        {
            title:"Set up your company details",
            click:() =>  history.push({pathname:ROUTES.ACCOUNT_SETTING,  search:`?tab=company-details`}),
            description:"This adds credibility to your profile and attract top-notch IT professionals for seamless collaborations",
            isComplete:companyDetailsExist
        },
        {
            title:"Set up a payment method",
            click:() => history.push(ROUTES.PAYMENT),
            description:"It will streamline the hiring process and ensure timely payments to IT professionals.",
            isComplete:paymentMethodExist
        },
    ]

    const TECH_SET_UP_STEPS = [
        {
            title:"Set up earning account",
            click:() =>  window.open(tech.Stripe_Connect_URL),
            description:"Unlock Your Earning Potential: Setting Up Your Earning Account"	,
            isComplete:!!tech?.Stripe_Connect_Id
        },
        {
            title:"Set up hourly rate",
            click:() => history.push(ROUTES.MANAGE_PROFILE_DETAILS),
            description:"Set your hourly rate and ensure fair compensation for your skills and time.",
            isComplete:!!tech?.Provider_Service
        },
        {
            title:"You verified your email",
            click:() => sendVerificationEmail({
                email: authUser.email,
                userId: authUser.id,
                token: authUser.token
            }),
            description:"It is essential to establish trust, and ensure secure communication."	,
            isComplete:tech?.email_verification_banner
        }
    ]

    const CLIENT_GUIDE_STEPS = [
        {
            icon:FindIcon,
            title:"Finding technicians",
            click:() => history.push(ROUTES.DISCOVERY_EXPERIENCE_SEARCH),
            description:"Discover the perfect IT professionals for your project. Learn how to search, filter, and review IT professional profiles based on their skills, experience, and ratings.",
            btnText:"Find Technicians"
        },
        {
            icon:InviteIcon,
            title:"Invite team members",
            click:() => history.push(ROUTES.TEAM_MANAGEMENT),
            description:"Collaborate seamlessly by inviting team members to TecMe. Customize your invitations, track their permissions, and work together on projects with ease.",
            btnText:"Invite Team Members"
        }
    ]

    const TECH_GUIDE_STEPS = [
        {
            icon:FindIcon,
            title:"Create Specializations",
            click:() => history.push(ROUTES.CREATE_SPECIALIZATION),
            description:"Showcase your IT prowess by creating specializations that encapsulate your areas of expertise. This tool allows you to highlight up to three specific IT categories.",
            btnText:"Create Specializations"
        },
        {
            icon:InviteIcon,
            title:"Choose Work Categories",
            click:() => history.push(ROUTES.MANAGE_SKILLS),
            description:"Collaborate seamlessly by inviting team members to TecMe. Customize your invitations, track their permissions, and work together on projects with ease.",
            btnText:"Choose Work Categories"
        },
        {
            icon:ServiceIcon,
            title:"Create IT Services",
            click:() => history.push(ROUTES.MANAGE_BOOKING),
            description:"This feature provides a platform to detail and promote your unique IT offerings. This self-service promotional tool facilitates a clear display of your IT expertise.",
            btnText:"Create IT Services"
        },
        {
            icon:SearchIcon,
            title:"Find Work",
            click:() => history.push(ROUTES.BROWSE_JOBS),
            description:"Discover IT Opportunities - Your Next Tech Project Awaits. Explore and Secure the Right IT Jobs for You.",
            btnText:"Find Work"
        },
        {
            icon:AddProjectIcon,
            title:"Add IT Projects",
            click:() => history.push(`${ROUTES.MANAGE_PROFILE_DETAILS}?section=it-projects`),
            description:"Showcase Your Skills - Add Your IT Projects to Build Your Portfolio. Let Clients Discover Your Expertise.",
            btnText:"Add IT Projects"
        },
        {
            icon:HistoryIcon,
            title:"Add Work History",
            click:() => history.push(`${ROUTES.MANAGE_PROFILE_DETAILS}?section=work-history`),
            description:"Boost Your Profile - Share Your Professional Journey. Highlight Your IT Expertise with Your Work History.",
            btnText:"Add Work History"
        },
    ]


    useEffect(() => {
        if(client){
            setSetUpSteps(CLIENT_SET_UP_STEPS)
            setGuideSteps(CLIENT_GUIDE_STEPS)
        }
        if(tech){
            setSetUpSteps(TECH_SET_UP_STEPS)
            setGuideSteps(TECH_GUIDE_STEPS)
        }
    },[client, tech, companyDetailsExist, paymentMethodExist])

    useEffect(() => {
        if(client){
            setSetUpSteps(CLIENT_SET_UP_STEPS)
            setGuideSteps(CLIENT_GUIDE_STEPS)
        }
        if(tech){
            setSetUpSteps(TECH_SET_UP_STEPS)
            setGuideSteps(TECH_GUIDE_STEPS)
        }
    },[paymentMethodExist])

    return (
        <div className='new-user-ctn'>
            {
               (contractData?.length === 0 && !loadingContracts) &&
                <div className='quick-start-guide'>
                    <div className='quick-start-heading'>
                        <Title level={3} style={{marginBottom:20, color:"#000"}}>Quick Start Guide</Title>
                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>We're excited to have you join our platform, where businesses like yours connect with talented IT professionals for all your technology needs. This Quick Start Guide will walk you through the key steps to get started and make the most out of TecMe.</Paragraph>
                    </div>
                    <Row className="card-section" gutter={[30, 30]} align={"stretch"}>
                        {
                           guideSteps.map((step) => {
                                return(
                                    <Col xs={24} sm={24} md={12} lg={client ? 12 : 8} xl={client ? 12 : 8}>
                                        <div className="guide-card" onClick={() => step.click()}>
                                            <div className="guide-icon">
                                                <img src={step.icon} alt="find icon" />
                                            </div>
                                            <div className='guide-content'>
                                                <Title level={5}>{step.title}</Title>
                                                <Paragraph className={client ? 'client-guide-description' : 'tech-guide-description'}>{step.description}</Paragraph>
                                                <Button type='primary' style={{width:"100%"}} onClick={() => step.click()}>{step.btnText}</Button>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            }
            {
                loadingPayments ? null :
                <>
                    {
                        ((client && (paymentMethodExist && companyDetailsExist && client?.email_verification_banner)) || tech && (!!tech?.Stripe_Connect_Id && !!tech?.Provider_Service && tech?.email_verification_banner)) ? null :
                            <div className='quick-start-guide'>
                                <div className='quick-start-heading'>
                                    <Title level={3} style={{marginBottom:20, color:"#000"}}>Complete these steps to stand out and hire fast</Title>
                                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>Setting up your TecMe account will increase your visibility and credibility, attracting more proposals from qualified IT professionals and expediting your hiring process.</Paragraph>
                                </div>
                                {/* <Row className="set-up-cards" gutter={[30, 30]} align={"stretch"}>
                                    {
                                        setUpSteps.map((step) => {
                                            return(
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <div className="set-up-card">
                                                        <div className="setup-step-icon">
                                                            <img src={step.isComplete ? CompleteIcon : IncompleteIcon} alt="check mark" />
                                                        </div>
                                                        <div className="setup-step-content">
                                                            <Title 
                                                            className={`${step.isComplete && 'disable-step'}`} 
                                                            level={5} 
                                                            onClick={() => {
                                                                if(step.isComplete) return
                                                                step.click()
                                                            }}>
                                                                {step.title}
                                                            </Title>
                                                            <Paragraph className={client ? 'client-step-description' : 'tech-step-description'}>{step.description}</Paragraph>
                                                            <div className="hire-required">
                                                                Required to Hire
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row> */}
                                <div className='steps-progress-ctn'>
                                    <div className='steps-progress-bar'>
                                        <Progress percent={Math.round((setUpSteps.filter((step) => step.isComplete).length / setUpSteps.length) * 100)} />    
                                    </div>
                                    <div className='steps-progress-items'>
                                        {
                                            setUpSteps.map((step, index) => {
                                                return(
                                                    <>
                                                    {
                                                        step.isComplete ?
                                                        <div key={step.title} className='steps-progress-item'> 
                                                            <img src={CompleteIcon} alt="check mark" />
                                                            <Paragraph delete>{step.title}</Paragraph>
                                                        </div> :
                                                        <div key={step.title} className='steps-progress-item'> 
                                                            <img src={IncompleteIcon} alt="check mark" />
                                                            <Paragraph> <span onClick={() => step.click()}>{step.title}</span> — {step.description}</Paragraph>
                                                        </div>
                                                    }
                                                    </>
                                                   
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </>
            }
        </div>
    )
}

export default NewUser