import { Avatar, Card, Form, Input, Layout, Modal, Select, Space, Switch, Typography, Upload, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../../../../../utils/constants";
import { MetaTags } from 'react-meta-tags'
import { InboxOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import './assets/css/modal.scss';
import { DeviceRepository } from '../repository/device-repository';
import { useSelector } from 'react-redux';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { DEVICE_BRANDS, getBase64 } from '../../../../../../utils/helper';
import { TroubleshootTwoTone } from '@mui/icons-material';

const { Content } = Layout;
const { Text, Paragraph } = Typography;
const { Dragger } = Upload;
const { useForm } = Form;

const AddEditDeviceModal = (props) => {
    const user = useSelector(state => state.user);
    const deviceRepositoryInstance = new DeviceRepository(user?.token);
    const [loading, setLoading] = useState(false);
    const [deviceBrands, setDeviceBrands] = useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [devicesMetaData, setDevicesMetaData] = useState(null)
    const [file, setFile] = useState(null)
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const [fileUrl, setFileUrl] = useState(null);
    const [deviceDetails, setDeviceDetails] = useState(null);
    const [form] = useForm()


    const getAndSetDeviceBrandsAndTypes = () => {
        deviceRepositoryInstance
          .getDeviceTypes()
          .then((response) => setDeviceTypes(response.data?.data))
          .catch((error) => console.error(error));
        deviceRepositoryInstance
          .getDeviceBrands()
          .then((response) => setDeviceBrands(response.data?.data))
          .catch((error) => console.error(error));
      };

    const getDevicesMetaData = () => {
        ApiBaseHelper.get({
            url: 'device/metadata',
        }).then((res) => {
            setDevicesMetaData(res?.data?.data)
        })
    }

    const getMetaDataInfo = (field) => {
        return devicesMetaData?.find((item) => item.Field === field)
    }

    const imgProps = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>
                    setFileUrl(imageUrl)
                );
                setFile(file)
                // console.log(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };


    const handleFormReset = () => {
        form.resetFields();
        setFile(null);
        setFileUrl(null);
        setProfileUpdateMessage("")
        setSelectedDeviceType(null)
        setSelectedBrand(null)
    }

    const handleDeviceDetailsSubmit = (values) => {
        setLoading(true)

        const data = new FormData();

        data.append("Active", values?.status === true ? 1 : 0)
        data.append("Type", values?.type || "")
        data.append("Other_Type", values?.specify_type || "")
        data.append("Make", values?.brand !== "other" ? values.brand : values.specify_brand)
        data.append("Model", values?.model || "")
        data.append("HardwareName", values?.nickname || "")
        data.append("HardwareDescription", values?.description || "")
        data.append("Hardware_Importance_Level", values?.importance || "")
        data.append("Hardware_Serial_Number", values?.serial_number || "")
        data.append("Service_Number", values?.service_number || "")
        data.append("Asset_tag", values?.asset_tag || "")
        data.append("MAC_Address", values?.mac_address || "")
        data.append("IP_Address", values?.ip_address || "")
        data.append("Physical_Surroundings", values?.physical_surroundings || "")
        data.append("Store_PHI", values.phi === true ? 1 : 0)
        data.append("Encrypted", values.encrypted === true ? 1 : 0)
        data.append("Mobile_Device", values.mobile_or_portable === true ? 1 : 0)
        if(file){
            data.append('file', file)
        }
        

        if(props.option === "add"){
            ApiBaseHelper.post({
                url: `device/v2`,	
                headers: {
                    Authorization: 'Bearer ' + user['token'],
                },
                payload: data
            }).then((res) => {
                console.log(res)
                if(res){
                    setLoading(false)
                    notification.success({
                        message: 'TecMe',
                        description: 'Device Added Successfully',
                    });
                    props.onOk()
                    props.fetchDevices()
                    handleFormReset()
                }
            }).catch((e) => {
                console.log(e)
                setLoading(false)
                notification.error({
                    message: 'TecMe',
                    description: 'Adding Device Failed',
                });
            })
        }else{
            ApiBaseHelper.put({
                url: `device/v2/${deviceDetails?.id}`,	
                headers: {
                    Authorization: 'Bearer ' + user['token'],
                },
                payload: data
            }).then((res) => {
                console.log(res)
                if(res){
                    setLoading(false)
                    notification.success({
                        message: 'TecMe',
                        description: 'Device Updated Successfully',
                    });
                    props.onOk()
                    props.fetchDevices()
                    handleFormReset()
                }
            }).catch((e) => {
                console.log(e)
                setLoading(false)
                notification.error({
                    message: 'TecMe',
                    description: 'Updating Device Failed',
                });
            })
        }

       
    }


    useEffect(() => {
        getAndSetDeviceBrandsAndTypes();
        getDevicesMetaData()
    }, []);

    useEffect(() => {
        if(props.option === "add"){
            form.setFieldsValue({
                status: true,
            })
        }
    }, [props.option])

    useEffect(() => {
        if(props.option === "edit"){   
            setDeviceDetails(props.location.state?.record)       
        }
    }, [props.location.state])

    useEffect(() => {
        if(deviceDetails && props.option === "edit"){
            form.setFieldsValue({
                status: deviceDetails?.Active === "true" ? true : false,
                type: deviceDetails?.Type,
                brand: deviceDetails?.Make,
                specify_type: deviceDetails?.Other_Type,
                // specify_brand: deviceDetails?.Make,
                model: deviceDetails?.Model,
                nickname: deviceDetails?.HardwareName,
                description: deviceDetails?.HardwareDescription,
                importance: deviceDetails?.Hardware_Importance_Level,
                serial_number: deviceDetails?.Hardware_Serial_Number,
                service_number: deviceDetails?.Service_Number,
                asset_tag: deviceDetails?.Asset_tag,
                mac_address: deviceDetails?.MAC_Address,
                ip_address: deviceDetails?.IP_Address,
                physical_surroundings: deviceDetails?.Physical_Surroundings === "undefined" ? "" : deviceDetails?.Physical_Surroundings,
                phi: deviceDetails?.Store_PHI === "true" ? true : false,
                encrypted: deviceDetails?.Encrypted === "true" ? true : false,
                mobile_or_portable: deviceDetails?.Mobile_Device === "true" ? true : false,
            })
            setFileUrl(deviceDetails?.Pic_1)
        }
    }, [deviceDetails, props.option])

    return (
    <Content className="main">
        <MetaTags>
            <title>{`${props.option === "add" ? "Add" : props.option === "edit" ? "Edit" : "Manage"} Device` } | TecMe</title>
            <meta name="description" content={CONSTANTS.META_DESCRIPTION} />
            <meta name="robots" content="noindex, nofollow" />
        </MetaTags>
        <Modal 
            title={`${props.option === "add" ? "Add" : "Edit"} Device` }
            width={693}
            open={props.open} 
            // onOk={props.onOk} 
            cancelButtonProps={{type:"secondary"}}
            okButtonProps={{loading: loading, htmlType:"submit", form:'update-device-form'}}
            onCancel={() => {
                props.onCancel()
                handleFormReset()
            }}>
                <Form
                    layout='vertical'
                    className="add-edit-device-form"
                    name='update-device-form'
                    onFinish={handleDeviceDetailsSubmit}
                    form={form}
                >      
                    <div
                        className='add-edit-form-switch'
                    >
                        <Paragraph style={{marginBottom:8}}>Active</Paragraph>
                        <Form.Item 
                            name="status"
                            // rules={[{ required: true, message: 'Please set device status' }]}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label="Device Type"
                        name="type"
                        rules={[{ required: true, message: 'Please select device type' }]}
                        className="add-edit-device-form-item required"
                    >
                        <Select 
                            placeholder="Select device type"
                            popupClassName='update-device-select'
                            onChange={(value) => setSelectedDeviceType(value)}
                            virtual={false}
                        >
                            {
                                getMetaDataInfo("Type")?.Values.filter((item) => item !== "Other").map((item, index) => {
                                    return <Select.Option key={index} value={item}>{item}</Select.Option>
                                })
                            }
                            <Select.Option key={"other"} value={"Other"}>Other</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        selectedDeviceType === "Other" || (props.option === "edit" && deviceDetails?.Other_Type) &&
                        <Form.Item
                            label="Specify Device Type"
                            name="specify_type"
                            className="add-edit-device-form-item required"
                            rules={[{ required: true, message: 'Please specify device type' }]}
                        >
                            <Input placeholder='Enter device type' />
                        </Form.Item>
                    }
                    { 
                    // selectedDeviceType &&
                    <Form.Item
                        label="Device Brand"
                        name="brand"
                        rules={[{ required: true, message: 'Please select device brand' }]}
                        className="add-edit-device-form-item required"
                        >
                            <Select 
                                placeholder="Select device brand"
                                popupClassName='update-device-select'
                                onChange={(value) => setSelectedBrand(value)}
                                virtual={false}
                            >
                                {
                                    DEVICE_BRANDS.map((item, index) => {
                                        return <Select.Option key={item} value={item}>{item}</Select.Option>
                                    })
                                }
                                <Select.Option key={"other"} value={"other"}>Other</Select.Option>
                            </Select>
                        </Form.Item>
                    }
                    {
                        selectedBrand === "other" &&
                        <Form.Item
                            label="Specify Device Brand"
                            name="specify_brand"
                            className="add-edit-device-form-item required"
                            rules={[{ required: true, message: 'Please specify device brand' }]}
                        >
                            <Input placeholder='Enter device brand' />
                        </Form.Item>   
                    }
                    <Form.Item
                        label="Device Nickname"
                        name="nickname"
                        className="add-edit-device-form-item required"
                        rules={[{ required: true, message: 'Please enter device nickname' }]}
                    >
                        <Input placeholder='Enter device nickname' />
                    </Form.Item>
                    <Form.Item
                        label="Device Model"
                        name="model"
                        className="add-edit-device-form-item"
                    >
                        <Input placeholder='Enter device model' />
                    </Form.Item>
                    <Form.Item
                        label="More details about your device"
                        name="description"
                        className="add-edit-device-form-item"
                    >
                        <Input.TextArea placeholder='Enter device description' rows={4}/>
                    </Form.Item>
                    <Form.Item
                        label="Importance Level"
                        name="importance"
                        extra={"How critical is the hardware to the organization or how critical is the PHI stored on it"}
                        className="add-edit-device-form-item"
                    >
                        <Select 
                            placeholder={"--None--"}
                            popupClassName='update-device-select'
                            virtual={false}
                        >
                            {
                                getMetaDataInfo("Importance Level")?.Values.map((item, index) => {
                                    return <Select.Option key={index} value={item}>{item}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Serial Number"
                        name="serial_number"
                        extra="Serial number on the hardware"
                        className="add-edit-device-form-item"
                    >
                        <Input placeholder='Enter device serial number'/>
                    </Form.Item>
                    <Form.Item
                        label="Service Number"
                        name="service_number"
                        extra="Service number used by many vendors for support"
                        className="add-edit-device-form-item"
                    >
                        <Input placeholder='Enter service number'/>
                    </Form.Item>
                    <Form.Item
                        label="Asset Tag"
                        name="asset_tag"
                        extra="Asset tag often used by organizations"
                        className="add-edit-device-form-item"
                    >
                        <Input placeholder='Enter asset tag'/>
                    </Form.Item>
                    <Form.Item
                        label="Mac Address"
                        name="mac_address"
                        extra="Usually a 12 digit HEX number"
                        className="add-edit-device-form-item"
                        rules={[{ pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, message: 'Please enter valid mac address' },
                        { max: 17, message: 'Mac address must not be more than 17 characters long' }]}
                    >
                        <Input placeholder='Enter mac address'/>
                    </Form.Item>
                    <Form.Item
                        label="IP Address"
                        name="ip_address"
                        extra="Internal IP address"
                        className="add-edit-device-form-item"
                        rules={[{ pattern: /^([0-9]{1,3}\.){3}[0-9]{1,3}$/, message: 'Please enter valid ip address'}]}
                    >
                        <Input placeholder='Enter IP address'/>
                    </Form.Item>
                    <Form.Item
                        label="Physical Surrounding"
                        name="physical_surroundings"
                        extra="Description of the general physical surrounding the hardware find itself in"
                        className="add-edit-device-form-item"
                    >
                        <Input placeholder='Enter physical surrounding'/>
                    </Form.Item>
                    <div className='add-edit-form-switch'>
                        <Paragraph style={{marginBottom:8}}>Able to store PHI</Paragraph>
                        <Form.Item 
                            name="phi"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                    <div className='add-edit-form-switch'>
                        <Paragraph style={{marginBottom:8}}>Encrypted</Paragraph>
                        <Form.Item 
                            name="encrypted"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                    <div className='add-edit-form-switch'>
                        <Paragraph style={{marginBottom:8}}>Mobile or Portable</Paragraph>
                        <Form.Item 
                            name="mobile_or_portable"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                    <Form.Item
                        // className="add-edit-device-form-item"
                        name="image"
                        extra={profileUpdateMessage && <Space>
                            <PaperClipOutlined />
                            <Text>{profileUpdateMessage}</Text>
                        </Space>}
                    >
                        <Dragger {...imgProps}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Only image file allowed</p>
                        </Dragger>
                    </Form.Item>
                    {
                        fileUrl &&
                        <Card>
                            <Avatar size={64} shape='square' src={fileUrl} icon={<UserOutlined/>} />
                        </Card>
                    }
                </Form>
        </Modal>
    </Content>
  )
}

export default AddEditDeviceModal