import React, {Component, Fragment} from 'react';
import {
    Alert,
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Image,
    Input,
    Layout,
    Modal,
    notification,
    Result,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    Typography
} from 'antd'
import {connect} from "react-redux";
import ROUTES from '../../../../../utils/routes';
import {BookingRepository} from './repository/repository';
import {DATE_TIME_FORMAT, META_DESCRIPTION, SECONDARY_COLOR} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import {Link} from "react-router-dom";
import Moment from 'moment';
import {extendMoment} from "moment-range";
import DesktopOutlined from "@ant-design/icons/lib/icons/DesktopOutlined";
import CarryOutOutlined from "@ant-design/icons/lib/icons/CarryOutOutlined";
import AimOutlined from "@ant-design/icons/lib/icons/AimOutlined";
import VideoCameraOutlined from "@ant-design/icons/lib/icons/VideoCameraOutlined";
import {isDesktop} from "react-device-detect";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import PaymentMethods from "../../../customer/book-service/components/payment-method-list";
import DollarOutlined from "@ant-design/icons/lib/icons/DollarOutlined";
import {canReadPageOnly, capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, ucwords} from "../../../../../utils/helper";
import RightCircleOutlined from "@ant-design/icons/lib/icons/RightCircleOutlined";
import NoResultsImage from "./assets/images/no-results.png";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";

const {RangePicker} = DatePicker;
const moment = extendMoment(Moment);

const {Content,} = Layout
const {Text, Title, Paragraph} = Typography;

class BookingDetails extends Component {
    filterFormRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            servicesLoading: true,
            subscriptionsLoading: true,
            paymentMethodModalVisible: false,
            showFilters: isDesktop,
            services: [],
            customerSelectedPaymentMethod: null,
            filteredData: [
                /*
                META DATA
                 */
                // {
                //     serviceId: '4232323',
                //     title: 'Custom support ticket',
                //     user: {
                //         name: 'Osama B.',
                //         type: 'tech',
                //         id: '123123',
                //         profilePic: 'https://static-01.daraz.pk/p/aec7101b86e4d8761ce7f70d341747eb.jpg',
                //     },
                //     supportType: 'ON SITE',
                //     status: 'Pending',
                //     scheduledDateTime: '2022-05-27T23:11:22.000Z',
                //     bookingRealStatus: 'Pending',
                //     bookingDisplayStatus: 'Active',
                //     bookingId: '123123'
                // }
            ],
            subscriptions: [],
        };
        this.getServices = this.getServices.bind(this);

        this.selectedSubscriptionId = null;
        this.user = this.props.user ? this.props.user : this.props.tech;
        this.bookingId = this.props.match.params.id;
        this.bookingRepository = new BookingRepository(this.props.user ? this.props.user.token : this.props.tech.token, this.props.user ? 'customer' : 'tech')
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.getServices();

    }
    async getServices() {
        //get bookings
        let bookingsResponse = await this.bookingRepository.getBookingDetails(this.bookingId);
        let booking = bookingsResponse.data.data;
        let services = [];
        // determine if all services are completed and tech has to send the invoice to customer
        let completedServicesCount = 0;
        let feeItems = 0;
        booking.items.map((service) => {
            if (service['status'] == 'Completed' || service['status'] == 'Cancelled') {
                completedServicesCount++;
            }
            if (service['type'] == 'fee')
                feeItems = 1;
        });

        let bookingStatus = 'Active';
        if (booking.status == 'Confirmed')
            bookingStatus = 'Paid';
        else if (booking.status == 'Completed')
            bookingStatus = 'Payment Required';
        else if (booking.status == 'Cancelled')
            bookingStatus = booking.status;
        else if (booking.accepted == false && this.props.tech)
            bookingStatus = 'New Request';
        else if (booking.accepted == false && this.props.user)
            bookingStatus = 'Pending';
        else if (booking.status == 'Pending' && (completedServicesCount == booking.items.length - feeItems))
            bookingStatus = 'Ready To Invoice'


        booking.items.map((service, serviceIndex) => {
            if (service.type == 'service')
                services.push({
                    serviceId: service.scheduledServiceId,
                    title: service.issue ? service.issue.title : 'N/A',
                    user: {
                        name: service.user.name,
                        type: service.user.type,
                        id: service.user.type == 'customer' ? service.customerContact : service.techContact,
                        profilePic: service.user.profilePic,
                    },
                    supportType: ucwords(service.mode.replace("_", " ")),
                    status: service.status == 'Accepted' ? 'Scheduled' : service.status,
                    scheduledDateTime: service.scheduledDateTime,
                    isRequest: bookingStatus == 'New Request',
                    bookingRealStatus: booking.status,
                    bookingDisplayStatus: bookingStatus,
                    bookingId: booking.bookingId
                })
        });
        this.setState({
            services,
            filteredData: services,
            servicesLoading: false
        })
    }

    render() {
        const {servicesLoading, services, filteredData, showFilters} = this.state;
        let _this = this;
        return (
            <Content>
                <MetaTags>
                    <title>Booking Details | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <div className="page">
                    <PageHeaderWrapper
                        title="Booking Details"
                        subTitle={
                            "List of all scheduled services belong to this booking"
                        }
                        routes={[
                            {
                                path: ROUTES.MANAGE_BOOKING,
                                breadcrumbName: 'Booking Details',
                            },

                        ]}
                    />
                    <div >
                        <div className="auth-container">
                            <Row gutter={[20, 20]} className={'mt-3'}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Card
                                        title={`Scheduled Services (${services.length})`}
                                        extra={
                                            this.props.user && !canReadPageOnly(this.props?.user?.role) && isDesktop ?
                                                <Button type={"primary"}>
                                                    <Link to={{pathname: '/'}}>
                                                        + Book A New IT Service
                                                    </Link>
                                                </Button>
                                                : null
                                        }
                                    >
                                        {
                                            services.length == 0 && servicesLoading == false &&
                                            <Result
                                                icon={<Image loading="lazy"  src={NoResultsImage} preview={false} style={{maxWidth: "250px"}}/>}
                                                title={this.props.user ? "Book A Technician" : "Find New Customer's"}
                                                subTitle={this.props.user ? "Search for local technicians that can repair your IT devices." : "Begin marketing your profile with the help of TecMe's marketing suite."}
                                                extra={
                                                    <Button type={"primary"}
                                                            onClick={() => {
                                                                if (this.props.tech)
                                                                    window.open("http://site.tecme.io/startup-guide", '_blank');
                                                                else
                                                                    this.props.history.push('/');
                                                            }}
                                                    >
                                                        {this.props.user ? "Search Now" : "Market Now"}
                                                    </Button>
                                                }
                                            />

                                        }

                                        <Row gutter={[15, 15]}>
                                            { // mobile only
                                                servicesLoading &&
                                                <>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Card
                                                            loading={true}
                                                        >
                                                            <Card.Meta
                                                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Card>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                services.length > 0 && servicesLoading == false &&
                                                <>
                                                    <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                        <Row gutter={[10, 10]}>
                                                            {
                                                                filteredData.map((record, index) => {
                                                                    let status = 'warning';
                                                                    if (record.status == 'Pending')
                                                                        status = 'default';
                                                                    else if (record.status == 'Accepted')
                                                                        status = 'warning';
                                                                    else if (record.status == 'In Progress')
                                                                        status = 'processing';
                                                                    else if (record.status == 'Completed')
                                                                        status = 'success';
                                                                    else if (record.status == 'Cancelled')
                                                                        status = 'error';

                                                                    let supportTypeIcon = <AimOutlined style={{fontSize: 20}}/>;
                                                                    if (record.supportType == 'REMOTE ACCESS')
                                                                        supportTypeIcon = <DesktopOutlined style={{fontSize: 20}}/>
                                                                    else if (record.supportType == 'VIDEO CALL')
                                                                        supportTypeIcon = <VideoCameraOutlined style={{fontSize: 20}}/>


                                                                    return <Col key={`service-${index}`} span={24}>
                                                                        <Card
                                                                            title={
                                                                                <Row align={"middle"} gutter={[5.5]}>
                                                                                    <Col>
                                                                                        {
                                                                                            record['user']['name'] == null ?
                                                                                                ''
                                                                                                : record['profilePic'] != null ?
                                                                                                <Avatar src={record['user']['profilePic']}/>
                                                                                                : <Avatar style={{
                                                                                                    color: '#f56a00',
                                                                                                    backgroundColor: '#fde3cf',
                                                                                                    fontSize: 20
                                                                                                }}>{record['user']['name'][0].toUpperCase()}</Avatar>
                                                                                        }
                                                                                    </Col>
                                                                                    {
                                                                                        this.props.user ?
                                                                                            <Col>
                                                                                                {record['user']['name'] != null ?
                                                                                                    <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                            href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['user']['id'])}>{capitalizeFirstWordOfEveryLetter(record['user']['name'])}</Button>
                                                                                                    : 'N/A'}
                                                                                            </Col>
                                                                                            :
                                                                                            <Col>
                                                                                                <Text>{record['user']['name'] != null ? capitalizeFirstWordOfEveryLetter(record['user']['name']) : 'N/A'}</Text>
                                                                                            </Col>
                                                                                    }
                                                                                </Row>
                                                                            }
                                                                            extra={<Tag>{record.bookingDisplayStatus}</Tag>}
                                                                        >
                                                                            <Text ellipsis={{rows: 2}} strong>{record.title}</Text>
                                                                            <Row gutter={[15, 15]} className={"mt-2"}>
                                                                                <Col span={24}>
                                                                                    <Row gutter={[10, 10]} align={"middle"}>
                                                                                        <Col>{supportTypeIcon}</Col>
                                                                                        <Col><Text>{record.supportType}</Text></Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Row gutter={[10, 10]} align={"middle"}>
                                                                                        <Col><CarryOutOutlined style={{fontSize: 20}}/></Col>
                                                                                        <Col> <Text>{moment(record.scheduledDateTime).format('llll')}</Text></Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Badge status={status} text={record.status}/>
                                                                                </Col>
                                                                                <Col xs={24} sm={24} md={12} lg={0} xl={0}>
                                                                                    <Button
                                                                                        danger
                                                                                        style={{width: '100%'}}
                                                                                    >
                                                                                        <Link to={{
                                                                                            pathname: `${ROUTES.SERVICE_DETAILS.replace(':service_id', record.serviceId).replace(":booking_id", record.bookingId)}`,
                                                                                        }}>
                                                                                            View Details
                                                                                        </Link>
                                                                                    </Button>
                                                                                </Col>

                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>

                                                    </Col>
                                                </>
                                            }
                                            {
                                                (services.length > 0 || servicesLoading == true) &&
                                                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                    <Table
                                                        style={{ minHeight: 500}}
                                                        loading={servicesLoading}
                                                        pagination={{
                                                            onChange: (e) => {
                                                                window.scrollTo(0, 0);
                                                            }
                                                        }}
                                                        rowClassName={(record, index) => {
                                                            return record.isRequest ? "service-request-row" : '';
                                                        }}
                                                        columns={[
                                                            {
                                                                title: 'Title',
                                                                key: 'title',
                                                                dataIndex: 'title',
                                                                web: true,
                                                                mobile: true,
                                                                className: "service-title-column",
                                                                sorter: {
                                                                    compare: (a, b) => a.title.localeCompare(b.title),
                                                                },
                                                                render: (record) => {
                                                                    return <div style={{width: 150}}>
                                                                        <Text ellipsis={{rows: 1}}>{capitalizeFirstLetter(record)}</Text>
                                                                    </div>
                                                                },
                                                            },

                                                            {
                                                                title: this.props.user ? 'Technician' : 'Customer',
                                                                dataIndex: 'user',
                                                                key: 'user',
                                                                web: true,
                                                                mobile: true,
                                                                render: (record) => {
                                                                    return <Row align={"middle"}>
                                                                        <Col>
                                                                            {
                                                                                record['name'] == null ?
                                                                                    ''
                                                                                    : record['profilePic'] != null ?
                                                                                    <Avatar src={record['profilePic']}/>
                                                                                    : <Avatar style={{
                                                                                        color: '#f56a00',
                                                                                        backgroundColor: '#fde3cf',
                                                                                        fontSize: 20
                                                                                    }}>{record['name'][0].toUpperCase()}</Avatar>
                                                                            }
                                                                        </Col>
                                                                        {
                                                                            this.props.user ?
                                                                                <Col>
                                                                                    {record['name'] != null ?
                                                                                        <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['id'])}>{capitalizeFirstWordOfEveryLetter(record['name'])}</Button>
                                                                                        : 'N/A'}
                                                                                </Col>
                                                                                :
                                                                                <Col>
                                                                                    <Text
                                                                                        style={{padding: '4px 15px'}}>{record['name'] != null ? capitalizeFirstWordOfEveryLetter(record['name']) : 'N/A'}</Text>
                                                                                </Col>
                                                                        }
                                                                    </Row>
                                                                },
                                                            },
                                                            {
                                                                title: 'Support Type',
                                                                key: 'supportType',
                                                                dataIndex: 'supportType',
                                                                web: true,
                                                                mobile: false,
                                                                sorter: {
                                                                    compare: (a, b) => a.supportType.localeCompare(b.supportType),
                                                                },
                                                            },
                                                            {
                                                                title: 'Status',
                                                                key: 'status',
                                                                dataIndex: 'status',
                                                                web: true,
                                                                mobile: false,
                                                                render: (record) => {
                                                                    let status = 'warning';
                                                                    if (record == 'Pending')
                                                                        status = 'default';
                                                                    else if (record == 'Accepted')
                                                                        status = 'warning';
                                                                    else if (record == 'In Progress')
                                                                        status = 'processing';
                                                                    else if (record == 'Completed')
                                                                        status = 'success';
                                                                    else if (record == 'Cancelled')
                                                                        status = 'error';

                                                                    return <Badge status={status} text={record}/>
                                                                },
                                                                sorter: {
                                                                    compare: (a, b) => a.status.localeCompare(b.status),
                                                                },
                                                            },
                                                            {
                                                                title: 'Scheduled Date & Time',
                                                                dataIndex: 'scheduledDateTime',
                                                                key: 'scheduledDateTime',
                                                                render: (record) => {
                                                                    return <Text>{record == null ? "N/A" : moment(record).format("llll")}</Text>
                                                                },
                                                                // defaultSortOrder: 'descend',
                                                                web: true,
                                                                mobile: false,
                                                                sorter: {
                                                                    compare: (a, b) => a.scheduledDateTime.localeCompare(b.scheduledDateTime),
                                                                },
                                                            },
                                                            {
                                                                title: 'Booking Status',
                                                                key: 'bookingDisplayStatus',
                                                                dataIndex: 'bookingDisplayStatus',
                                                                web: true,
                                                                mobile: false,
                                                                sorter: {
                                                                    compare: (a, b) => a.bookingDisplayStatus.localeCompare(b.bookingDisplayStatus),
                                                                },
                                                                render: (record) => {
                                                                    return record == 'New Request' ?
                                                                        <Badge status={'error'} text={record}/>
                                                                        : <Text>{record}</Text>
                                                                },
                                                            },
                                                            {
                                                                title: 'View',
                                                                key: 'action',
                                                                render: (text, record, index) => {
                                                                    return <Tooltip title="View service detail">
                                                                        <Link to={{
                                                                            pathname: `${ROUTES.SERVICE_DETAILS.replace(':service_id', record.serviceId).replace(":booking_id", record.bookingId)}`,
                                                                        }}>
                                                                            <RightCircleOutlined style={{fontSize: 26, color: SECONDARY_COLOR}}/>
                                                                        </Link>
                                                                    </Tooltip>
                                                                },
                                                            },
                                                        ]}
                                                        dataSource={filteredData}/>
                                                </Col>
                                            }

                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Content>
        )
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(BookingDetails);
