import React, { useEffect, useState } from 'react'
import { META_DESCRIPTION, META_IMAGE, SECONDARY_COLOR } from '../../../../../utils/constants'
import { MetaTags } from 'react-meta-tags'
import { Col, Layout, Row, Typography } from 'antd'
import ItcHero from './components/hero'
import './assets/css/styles.scss'
import ItcAbout from './components/about'
import ItcServices from './components/services'
import ItcProcesses from './components/processes'
import ItcProfessionals from './components/professionals'
import ItcTestimonials from './components/testimonials'
import ItcFaq from './components/faq'
import FreeTrial from './components/free-trial'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useHistory, useParams, Link } from 'react-router-dom'
import PageFallback from '../../../../components/page-fallback'
import ItcNav from './components/nav'
import { useDispatch, useSelector } from 'react-redux'
import { generateAiResponse } from '../../../../../utils/openai-helper'
import ItcFooter from './components/footer'
import { RtmRepository } from '../../../common/private/real-time-messaging/repository/rtm-repository'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PageNotFoundImage from '../../../common/public/general/page-not-found/assests/images/page-not-found.png';
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import { updateWorkCategories } from '../../../../../redux/reducers/app'
import { capitalizeFirstWordOfEveryLetter, convertIfLight } from '../../../../../utils/helper'
import ROUTES from '../../../../../utils/routes'

const { Content } = Layout
const { Title, Paragraph } = Typography


const ItcLandingPage = () => {
    const subdomain = window.location.hostname.split('.')[0];

    const params = useParams()
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const history = useHistory()
    const [loadingContents, setLoadingContents] = useState(false)
    const [loadingGeneratedContent, setLoadingGeneratedContent] = useState(false)
    const [itcContent, setItcContent] = useState({})
    const [activeConversationId, setActiveConversationId] = useState('')
    const [memberItcProfiles, setMemberItcProfiles] = useState([])
    const [loadingMemberProfiles, setLoadingMemberProfiles] = useState(false)
    const [groupConversationId, setGroupConversationId] = useState(null)
    const [loadingGroupConversation, setLoadingGroupConversation] = useState(false)
    const [error, setError] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const dispatch = useDispatch()

    const token = user?.token || tech?.token
    const activeUser = user || tech

    const fetchItcWebContents = () => {
        setLoadingContents(true)
        ApiBaseHelper.get({
            url: `web/itc/contents/${params?.subdomain || subdomain}`
        }).then((res) => {
            setItcContent(res?.data?.data[0])
            setLoadingContents(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContents(false)
            setError(true)
        })
    }

    const fetchWorkCategories = () => {
        ApiBaseHelper.get({
            url: `work-categories`,
        }).then((res) => {
            dispatch(updateWorkCategories(res.data))
        }).catch((error) => {
            console.error(error)
        })
    }

    function correctJsonFormat(generatedString) {
        // Remove any leading/trailing non-JSON characters (if necessary)
        let trimmedString = generatedString.trim().replace(/^['"]+|['"]+$/g, '');
    
        // Correctly quote property names: look for patterns like propertyName: and replace with "propertyName":
        let correctedString = trimmedString.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":');
    
        // Replace single quotes with double quotes around values, trying to avoid affecting single quotes within the data
        correctedString = correctedString.replace(/: '([^']*)'/g, ': "$1"');
    
        // Attempt to parse the corrected string as JSON
        try {
            return JSON.parse(correctedString);
        } catch (error) {
            console.error("Failed to parse corrected string as JSON:", error, correctedString);
            return null; // or return the original string, or handle the error as appropriate
        }
    }


    // Function to get and set active conversation id for the user
    const getAndSetActiveConversationId = async () => {
        if(activeUser) {
            await FirebaseHelper.login();
            let conversationExists = await FirestoreHelper.getConversationBetweenUsers({
                user_1: activeUser['id'],
                user_2: itcContent?.Owner_Contact,
            });
            let conversationId = conversationExists.length > 0 ? conversationExists[0].id : null;
            setActiveConversationId(conversationId)
        } else{
            setActiveConversationId(null)
        }
    }

     // Function to get and set group conversations for the commpany
     const getAndSetGroupCoversation = async () => {
        setLoadingGroupConversation(true)
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        setGroupConversationId(groupConversations.find((c) => c.group.toLowerCase() === (itcContent?.Company_Name?.toLowerCase()))?.id)
        setLoadingGroupConversation(false)
    }

    // get member itc profiles to check if tech belong to any
    const getMemberItc = () => {
        setLoadingMemberProfiles(true)
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Active`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setMemberItcProfiles(res?.data?.data?.filter((item) => item !== null))
            setLoadingMemberProfiles(false)
        }).catch((error) => {
            console.error(error)
            setLoadingMemberProfiles(false)
        })
    }

  

    let brandColor = convertIfLight(`#${itcContent?.Brand_Color}`)
    
    useEffect(() => {
        if(tech && itcContent){
            getAndSetGroupCoversation()
        }
    }, [tech, itcContent])

    useEffect(() => {
        if(itcContent){
            getAndSetActiveConversationId()
        }
    }, [user, tech, itcContent])

    useEffect(() => {
        fetchItcWebContents()
    }, [])

    useEffect(() => {
        if(tech){
            getMemberItc()
        }
    }, [tech])

    useEffect(() => {
        fetchWorkCategories()
    }, [])

    useEffect(() => {
        if(itcContent?.Owner_Contact === tech?.id && ROUTES.ITC_HOME.replace(':subdomain', itcContent?.Subdomain) === window.location.pathname){
            setCanEdit(true)
        }
    }, [itcContent, tech])

    if(loadingContents || loadingGroupConversation || loadingGeneratedContent){
        return <PageFallback />
    }

    return (
        <Content>
            <MetaTags>
                <title> {capitalizeFirstWordOfEveryLetter(itcContent?.Company_Name)} | TecMe | Home & Business IT On-Demand</title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="prerender-status-code" content="200"/>
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={`TecMe | Home & Business IT On-Demand`} />
                <meta property="og:description" content={META_DESCRIPTION} />
                <meta property="og:image" content={META_IMAGE} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="prerender-status-code" content="200"/>
                <meta name="robots" content="index, follow" />
            </MetaTags>
            {
                error ?
                <section className='error-section'>
                    <div className="error-row">
                        <div className="error-col">
                            <Title level={1} className='mb-4' style={{color:SECONDARY_COLOR, fontSize:50}}>Sorry, this ITSC page appears to be inactive</Title>
                            <Paragraph className="mb-4" style={{
                                color: '#B1B1B1',
                                fontWeight: 400,
                            }}>The page you are looking for could not be found. Confirm if page is active and double check the page URL or click the link below to go back</Paragraph>
                            <p onClick={() => window.history.back()} style={{textDecoration: 'none', color: 'black', cursor:"pointer"}}><ArrowBackIcon fontSize="small"/> Go Back </p>
                        </div>
                        <div className="error-col">
                            <div className='page-not-found-img'>
                                <img src={PageNotFoundImage} alt="page not found image" />
                            </div>
                        </div>
                    </div>
                </section>
                :
                <>
                    <ItcNav
                        itcContent={itcContent}
                        brandColor = {brandColor}
                    />
                    <ItcHero
                        brandColor = {brandColor}
                        activeConversationId={activeConversationId}
                        groupConversationId={groupConversationId}
                        token={token}
                        itcContent={itcContent}
                        memberItcProfiles={memberItcProfiles}
                        canEdit={canEdit}
                    />
                    <ItcAbout 
                        itcContent={itcContent}
                        brandColor = {brandColor}
                        canEdit={canEdit}
                        token={token}
                    />
                    <ItcServices 
                        itcContent={itcContent}
                        brandColor = {brandColor}
                    />
                    <ItcProcesses
                        brandColor = {brandColor}
                        itcContent={itcContent}
                        correctJsonFormat={correctJsonFormat}
                        token={token}
                        canEdit={canEdit}
                    />
                    <ItcProfessionals
                        itcContent={itcContent} 
                        brandColor = {brandColor}
                    />
                    <ItcTestimonials 
                        brandColor = {brandColor}
                        itcContent={itcContent}
                        activeConversationId={activeConversationId}
                    />
                    <ItcFaq 
                        brandColor = {brandColor}
                        itcContent={itcContent}
                    />
                    {/* <FreeTrial 
                        brandColor = {brandColor}
                    /> */}
                    <ItcFooter
                        itcContent={itcContent}
                        brandColor = {brandColor}
                        activeConversationId={activeConversationId}
                        token={token}
                        groupConversationId={groupConversationId}
                        memberItcProfiles={memberItcProfiles}
                    />
                </>
            }
        </Content>
  )
}

export const brandColor = '#2771A9'

export default ItcLandingPage