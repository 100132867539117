import { DatePicker, Form, notification, Modal, Select, Space, Button, TimePicker, Typography, Divider } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTimeSheet } from '../../hooks/useTimesheet';
import TextArea from "antd/es/input/TextArea";
import { ContractRepository } from "../../repository";
import { convertToLocalTime } from '../../../../../../../../utils/helper';

const { RangePicker } = DatePicker;
const { useForm } = Form

const ManualTimeModal = ({
    showTimeEntryModal,
    setShowTimeEntryModal,
    upComingInvoice,
    filteredTickeData,
    repository,
    contractId,
    maxAllowableHours,
    setUpdatedTimesheetData,
    contractDetails,
    ticketId,
    getAndSetTicketDetails,
    getAndSetActivities,
    getAndSetFilteredTimeSheetsData,
    ticketDetails,
    isFromTimesheet=false
}) => {

    // const {billingWeekTotalHours,timeSheetData} = useTimeSheet(contractId)

    const [addingManualTime, setAddingManualTime] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [timeRange, setTimeRange] = useState([])
    const [timeDuration, setTimeDuration] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null);

    const [timeSpent, setTimeSpent] = useState(0)
    // const [timeSheetData, setTimeSheetData] = useState([])
    const [form] = useForm()

    const ticketCreatedDate = startDate ? moment(moment.utc(startDate).toDate()).local()  : moment(moment.utc(ticketDetails?.CreatedDate).toDate()).local() 
    
    // disable dates before the 7 days of the upcoming invoice next_payment_attempt date and after the current date
    const disabledDate = (current) => {
        let currentDay = moment(current).format('YYYY-MM-DD');
        currentDay = moment(currentDay);
        let activeDateTime = startDate ? moment(startDate) : moment(ticketDetails?.CreatedDate);
        activeDateTime.startOf('day');
        return current < activeDateTime || currentDay >= moment() || (upComingInvoice ? currentDay < moment.unix(upComingInvoice.next_payment_attempt).subtract(8, 'day') : false)
    };

    const getTimeSheetData = () => {
        repository?.getTimesheets(contractId).then(async res => {
            if (res.data?.data?.length) {
                const sortedTimesheetData = [...res?.data.data]
                sortedTimesheetData?.sort((a, b) => moment(b.Clock_Out_Date_Time).local().diff(moment(a.Clock_Out_Date_Time).local()))
                setWeeklyTimeSpent(sortedTimesheetData)
            }
        })

    }
    const setWeeklyTimeSpent = (timeSheetData) => {
        const daysFrom = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') : moment().startOf('week');
        const daysTo = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt) : moment().endOf('week');
        const thisWeekTimesheet = timeSheetData.filter((item) => {
            return moment(item.Clock_Out_Date_Time) >= daysFrom && moment(item.Clock_Out_Date_Time) <= daysTo
        })

        const timeSpent2 = thisWeekTimesheet.reduce((acc, item) => {
            return acc + parseInt(item.Total_Time)
        }
            , 0)

        setTimeSpent(timeSpent2 / 60)
    }

    const handleTicketChange = (value) => {
        const selectedTicket = filteredTickeData.find((ele) => ele.id === value)
        setStartDate(selectedTicket?.CreatedDate)
    };

    const handleAddManualTimeSubmit = (values) => {

        const timeRange = values.time_range;
        const selectedDate = values.date;

        // Creating the moment objects
        const clockInDateTime = moment(selectedDate).set({
            hour: timeRange[0].hour(),
            minute: timeRange[0].minute(),
            second: 0,
            millisecond: 0
        });

        const clockOutDateTime = moment(selectedDate).set({
            hour: timeRange[1].hour(),
            minute: timeRange[1].minute(),
            second: 0,
            millisecond: 0
        });

        const payload = {
            Clock_In_Date_Time: clockInDateTime.utc().format(),
            Clock_Out_Date_Time: clockOutDateTime.utc().format(),
            Contract: contractId,
            Customer_Contact: contractDetails?.Customer_Org?.HIPAA_Primary_Contact,
            Ticket: ticketId ? ticketId : values.ticket ? values.ticket : null,
            Notes: values.Notes,
        }

        setAddingManualTime(true)
        repository.addManualTime(contractId, payload).then(async (res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: res.data.message,
                });
                setAddingManualTime(false)
                form.resetFields()
                setShowTimeEntryModal(false)

                if (getAndSetTicketDetails)
                    await getAndSetTicketDetails()
                if (getAndSetActivities)
                    await getAndSetActivities()
                if (getAndSetFilteredTimeSheetsData)
                    await getAndSetFilteredTimeSheetsData()
                if (setUpdatedTimesheetData)
                    setUpdatedTimesheetData(true)

            }
        }).catch(error => {
            console.error(error)
            setAddingManualTime(false)
            notification['error']({
                message: 'TecMe',
                description: "Add manual time entry failed."
            });
            form.resetFields()
        })
    }


    useEffect(() => {
        if (showTimeEntryModal) {
            if (setUpdatedTimesheetData)
                setUpdatedTimesheetData(false)
        }
    }, [showTimeEntryModal])

    useEffect(() => {
        getTimeSheetData()
    }, [contractId])

    useEffect(() => {
        if(selectedDate && selectedDate.isSame(ticketCreatedDate, 'day')){
            const clockInLocal =  convertToLocalTime(ticketCreatedDate)
        
            form.setFieldsValue({
              time_range: [clockInLocal, ""],
            });
        }
      }, [selectedDate]);

    const getEstimatedTime = () => {
        if (timeRange.length) {
            let estimatedTime = moment.duration(moment(timeRange[1]).diff(timeRange[0]))
            estimatedTime.add(1, 'seconds')
            return `${estimatedTime.hours()}h ${estimatedTime.minutes()}m`
        }
    }

    return (
        <Modal title="Add Manual Time"
            open={showTimeEntryModal}
            okText={"Submit"}
            confirmLoading={addingManualTime}
            onOk={() => {
                form.submit()
            }}
            onCancel={() => {
                setShowTimeEntryModal(false)
                form.resetFields()
            }}
        >
            <Form
                onFinish={(values) => handleAddManualTimeSubmit(values)}
                layout={'vertical'}
                form={form}
            >
                {
                    // if ticket id is not passed then show ticket dropdown
                    !ticketId &&
                    <Form.Item
                        label="Support Ticket"
                        name="ticket"
                        rules={[
                            {
                                required: true,
                                message: 'Please select support ticket',
                            },
                        ]}
                    >
                        <Select placeholder="Select support ticket" onChange={handleTicketChange}>
                            {
                                filteredTickeData?.map((ele) =>
                                    <Select.Option value={ele.id}>{ele.Summary}</Select.Option>
                                )
                            }

                        </Select>
                    </Form.Item>
                }

                <Form.Item name="date" label="Date"
                    rules={[
                        {
                            required: true,
                            message: 'Please select date',
                        },
                    ]}
                >
                    <DatePicker
                        format={"ddd, MMM D, YYYY"}
                        style={{ width: '100%' }}
                        disabledDate={disabledDate}
                        showToday={false}
                        disabled={!startDate && isFromTimesheet}
                        onChange={(date, dateString) => {
                            setSelectedDate(date);
                            if (date && date.isSame(ticketCreatedDate, 'day')) {
                                setTimeRange([ticketCreatedDate, ticketCreatedDate]);
                              } else {
                                setTimeRange([]);
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item name="time_range" label="From"
                    rules={[
                        {
                            required: true,
                            message: 'Please select start and end time',
                        },
                        {
                            validator(_, value) {
                                if (value) {
                                    const startTime = value[0]
                                    const endTime = value[1]
                                    const timeDifferenceStartEndTime = endTime.diff(startTime, 'minutes')
                                    const hoursDiff = (timeDifferenceStartEndTime / 60).toFixed(2)
                                    const totalUsedTime = parseFloat(hoursDiff) + parseFloat(timeSpent.toFixed(2))
                                    const disableHour = ticketCreatedDate.hour();
                                    const disableMinute = ticketCreatedDate.minute();
                                    const startHour = startTime.hour();
                                    const startMinute = startTime.minute();
        
                                    // Compare only time
                                    const disableTime = disableHour * 60 + disableMinute;
                                    const startTimeInMinutes = startHour * 60 + startMinute;

                                    if (startTime.isAfter(endTime))
                                        return Promise.reject(new Error('Start time should be before than end time'))

                                    // start time and end time are same
                                    if (timeDifferenceStartEndTime == 0)
                                        return Promise.reject(new Error('Start time can not be the same as end time'))

                                    // start time should be after ticket creation time
                                    if (selectedDate.isSame(ticketCreatedDate, 'day') && startTimeInMinutes < disableTime) {
                                        return Promise.reject(new Error('Start time should be after ticket creation time'));
                                    }

                                    // no weekly hour limit is applied by client so no need to check
                                    if (maxAllowableHours == null || maxAllowableHours == undefined || maxAllowableHours == '' || maxAllowableHours == 0)
                                        return Promise.resolve();

                                    // if weekly hours limit crossed, throw error
                                    if (parseFloat(totalUsedTime) <= Number(maxAllowableHours))
                                        return Promise.resolve();
                                    else
                                        return Promise.reject(new Error('You have exceeded your maximum weekly work hours '))

                                }
                                
                                return Promise.reject(new Error('Please select start and end time'))
                            }
                        }
                    ]}

                >
                    {/* <TimePicker.RangePicker
                    disabledTime={(current) => {

                        if (!selectedDate)
                            return {
                                disabledHours: () => [],
                            }
                        if (upComingInvoice) {
                            let billingPeriodStartDate = moment.unix(upComingInvoice.next_payment_attempt).subtract(8, 'day');
                            console.log({
                                selectedDate,billingPeriodStartDate,
                                disabled:  Array.from({ length: billingPeriodStartDate.hour() }, (_, i) => i),
                                logic: selectedDate.startOf('day').isSame(billingPeriodStartDate.startOf('day'))})
                            if (selectedDate.startOf('day').isSame(billingPeriodStartDate.startOf('day'))) {
                                return {
                                    // disabledHours: () => range(0, billingPeriodStartDate.hour()),
                                    disabledHours: () => Array.from({ length: billingPeriodStartDate.hour() }, (_, i) => i),
                                    // disabledMinutes: () => range(0, 60),
                                    // disabledSeconds: () => range(0, 60),
                                }
                            }
                            return {
                                disabledHours: () => [],
                            }
                        }
                        return {
                            disabledHours: () => [],
                        }
                    }}

                    /> */}

                    <TimePicker.RangePicker
                        style={{ width: "100%" }}
                        format="h:mm A"
                        onChange={(time, timeString) => {
                            setTimeRange(time)
                        }}
                        value={timeRange}
                        disabled={!selectedDate}
                        use12Hours
                        inputReadOnly
                        disabledTime={(_, type) => {
                            // current is a moment object for the current hovering date and time
                            if (type === 'start' && selectedDate && selectedDate.isSame(ticketCreatedDate, 'day')) {
                                const disableHour = ticketCreatedDate.hour();
                                const disableMinute = ticketCreatedDate.minute();
                                const isPM = disableHour >= 12;
                                return {
                                  disabledHours: () => {
                                    const hours = [];
                                    if (isPM) {
                                        for (let i = 0; i < 12; i++) {
                                            hours.push(i); // Disable all AM hours
                                        }
                                        for (let i = 12; i < disableHour; i++) {
                                            hours.push(i); // Disable PM hours before ticketCreatedDate hour
                                        }
                                    } else {
                                        for (let i = 0; i < disableHour; i++) {
                                            hours.push(i); // Disable AM hours before ticketCreatedDate hour
                                        }
                                    }
                                    return hours;
                                  },
                                  disabledMinutes: (selectedHour) => {
                                    const minutes = [];
                                    const hourIn12Format = disableHour % 12 || 12;
                                    if (selectedHour % 12 === hourIn12Format) {
                                        for (let i = 0; i < disableMinute; i++) {
                                            minutes.push(i);
                                        }
                                    }
                                    console.log(minutes)
                                    return minutes;
                                  },
                                };
                              }
                              return {};
                        }}
                    />

                </Form.Item>
                {
                    timeRange.length > 0 &&
                    <Typography.Text>Estimated time: {getEstimatedTime()}</Typography.Text>

                }
                <Divider />
                <Form.Item
                    label="Memo (optional)"
                    name="Notes"
                >
                    <TextArea
                        placeholder="If applicable, you may add any notes here"
                        rows={5}
                        showCount
                        maxLength={140}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ManualTimeModal